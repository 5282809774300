import React from 'react'

export const showSpinner=()=>{
    document.getElementById("loader").classList.add("d-block")
}

export const hideSpinner=()=>{
    document.getElementById("loader").classList.remove("d-block")
}

export default function SpinnerLoader() {
    return (
        <div className="display" id="loader">
            <div className="d-flex justify-content-center mtmt align-items-center h-100">
                <span className="spinner-border text-white" id="spinner"></span>
            </div>
        </div>
    )
}

