import React from "react";
import FormPopup from "../form-popup";
import InputGroup from "../../inputs/input-group";
import FileSelector from "../../inputs/file-selector";
import ProcessLoader from "../../general/process-loader";
import SelectGroup from "../../inputs/select-group";

export default function ProductImagePopup({id, title, onSave, onCancel, formData, hook, values,update,color}) {
    const { handleInputChange, handleSubmit, onFileSelect, data, uploadInProgress, apiInProgress} = hook({onSave, formData, values, update});
    return(
        <FormPopup title={title} onSave={handleSubmit} onCancel={onCancel} update={update} >
            <SelectGroup value={data.colorId} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Color*" name="colorId" list={color} placeHolder="Color" required /> 
            <InputGroup value={data.name} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Name*" name="name"  type="text" placeHolder="Enter name"  required/>
            {/* <TextArea value={data.description} className="mb-3 col-md-12" onChange={handleInputChange} label="Description" name="description" placeHolder="Enter description"  /> */}
            <FileSelector onFileSelect={onFileSelect} label="Upload icon" showProgress={uploadInProgress} value={data.imageUrl} update={update} required />
            {apiInProgress && <ProcessLoader />}
        </FormPopup>
    )
}