import { get } from "jquery";
import { getOrderHistory, getOrderHistorybyId, updateOrderbyId } from "./ApiUrls";
import { Delete, Put } from "./Service";


export const getOrder = async (customerId) => {
    const response = await get(getOrderHistory(customerId));
    if(!response) {
        return []
    }
    return response;
};

export const getOrderById = async (customerId) => {
    const response = await get(getOrderHistorybyId(customerId));
    if(!response) {
        return []
    }
    return response;
};


export const updateOrder = async (orderId,status) => {
    const response = await Put(updateOrderbyId(orderId,status));
    return response;
};


export const deleteOrder = async (orderId) => {
    const response = await Delete(deleteOrder(orderId));
    return response;
};