import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { getUploadApiUrl } from '../../service/ApiUrls'
import { upload } from '../../service/Service'
import { getProductDetail, saveProduct, updateProduct } from '../../service/productService';
import { deleteColorByProduct, saveColorByProduct, updateColorByProduct } from '../../service/productColorService';
import { saveImagebyColorProduct, updateImagebyColorProduct } from '../../service/productImageService';
import { deleteProductSkuTypebyColorProduct, saveProductSkuTypebyColorProduct, updateProductSkuTypebyColorProduct } from '../../service/productSkuTypeService';
import { deleteProductSkubyColorProduct, saveProductSkubyColorProduct, updateProductSkubyColorProduct } from '../../service/productSkuService';
import { saveProductPricebyColorProduct, updateProductPriceTypebyColorProduct } from '../../service/productPriceService';
import { saveAttributeByProduct, updateAttributeByProduct } from '../../service/productAttributeService';
import { toast } from '../../components/general/alert';
import { hideSpinner, showSpinner } from '../../components/general/spinner';
import { getStock, saveStock, updateStock } from '../../service/StockService';

export default function useProductForm({ id, onSave, formData, update, setShowForm }) {

    const { tenantId } = useParams();

    const newData = { name: "", description: "", category: "", brand: "", imageUrl: undefined, deleted: false, tenantId, replacement: false, replacementDays: 0, warranty: false, warrantyPeriod: "0", btnDisabled:update ? false : true }
    const [data, setData] = useState(formData !== undefined ? { ...formData, "tenantId": tenantId,btnDisabled:false } : { ...newData })

    const [files, setFiles] = useState([])

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const [apiInProgress, setApiInProgress] = useState(false)

    const [attribute, setAttribute] = useState({ colorId: "", label: "", attValue: "", isPrice: "", wasPrice: "", discountType: "", discount: "", skuTypeName: "", skuTypeValue: "", skuValue: "" })

    const [ProductColor, setProductColor] = useState([])

    const [price, setPrice] = useState({ isPrice: "", wasPrice: "", discountType: "", discount: "" })

    const [productId, setProductId] = useState("")

    const [colorId, setColorId] = useState([])




    const colorType = [
        // {name:"Rgba",key:"RGBA"},
        { name: "True Color", key: "TRUE_COLOR" },
        { name: "Hex", key: "HEX" },
        // {name:"Image",key:"IMAGE"},
    ]

    const discountType = [
        { name: "Percentage", key: "PERCENTAGE" },
        { name: "Amount", key: "AMOUNT" }
    ]

    useEffect(() => {
        if (update) {
            // fetchProduct() 
            fetchDetail()
        }
    }, [])

    useEffect(()=>{

        if(!update){
            setData(state => ({...state, btnDisabled:true}))
        }
    },[update])

    const fetchDetail = async () => {
        setProductId(formData.id)
        data.category = formData.categoryId !== null ? formData.categoryId : ""
        data.brand = formData.brandId !== null ? formData.brandId : ""

        const response = await getProductDetail(tenantId, formData.id)
        data.replacement = response.product.replacement
        data.replacementDays = response.product.replacementDays
        data.warranty = response.product.warranty
        data.warrantyPeriod = response.product.warrantyPeriod
        let attributes = []
        if (formData.attributes !== null && formData.attributes.length > 0) {
            for (let q = 0; q < formData.attributes.length; q++) {
                if (formData.attributes[q].deleted === false) {
                    attributes.push({ attributeId: formData.attributes[q] !== null ? formData.attributes[q].id : "", label: formData.attributes[q] !== null ? formData.attributes[q].label : "", attValue: formData.attributes[q] !== null ? formData.attributes[q].value : "", disabled: false })
                }
            }
        }
        let colors = []
        let color = []
        if (response.colors !== null) {
            if (response.colors.length > 0) {
                for (let i = 0; i < response.colors.length; i++) {
                    if (response.colors[i].color.deleted === false) {
                        color.push(response.colors[i].color.id)
                        let sizes = []
                        if (response.colors[i].productSkuTypeDetail.length > 0) {
                            for (let j = 0; j < response.colors[i].productSkuTypeDetail.length; j++) {
                                if (response.colors[i].productSkuTypeDetail[j].productSkuType.deleted === false) {
                                    let skuType = response.colors[i].productSkuTypeDetail[j].productSkuType
                                    let sku = response.colors[i].productSkuTypeDetail[j].productSku
                                    let resp;
                                    if(Object.keys(sku[0]).includes("id")){
                                         resp = await getStock(tenantId,sku[0].id )
                                    }
                                    sizes.push({ priceId: sku.length > 0 && sku[0].productPrice !== null ? sku[0].productPrice.id : "", isPrice: sku.length > 0 && sku[0].productPrice !== null ? sku[0].productPrice.isPrice : "", wasPrice: sku.length > 0 && sku[0].productPrice !== null ? sku[0].productPrice.wasPrice : "", discountType: sku.length > 0 && sku[0].productPrice !== null ? sku[0].productPrice.discountType : "", discount: sku.length > 0 && sku[0].productPrice !== null ? sku[0].productPrice.discount : "", skuTypeId: skuType.id, skuTypeName: skuType.name, skuTypeValue: skuType.value, skuValue: sku.length > 0 ? sku[0].value : "", skuId: sku.length > 0 ? sku[0].id : "", skuIdA: sku.length > 0 ? sku[0].skuId : "", preOrder:sku.length > 0 ?sku[0].preOrder : "", stockId: Object.keys(resp).length !== 0 ? resp.id : "", btnDisabled:false, availableStocks: Object.keys(resp).length !== 0 ? resp.availableStock : "" })
                                }
                            }
                        }
                        colors.push({ colorId: response.colors[i].color.id, name: response.colors[i].color.name, type: response.colors[i].color.type, value: response.colors[i].color.value, productImages: response.colors[i].color.productImages, sizes: sizes, attributes: attributes, btnDisabled:false })
                    }
                }
            }
            setProductColor(colors)
            setColorId(color)
        } else {
            setProductColor([])
        }

    }
    console.log(ProductColor);
    const handleInputChange = (event) => {
        event.stopPropagation()
        setData((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }));
        if (event.target.name === "replacement") {
            if (event.target.checked === true) {
                setData(state => ({ ...state, [event.target.name]: true }))
            } else {
                setData(state => ({ ...state, [event.target.name]: false }))

            }
        }
        if (event.target.name === "warranty") {
            if (event.target.checked === true) {
                setData(state => ({ ...state, [event.target.name]: true }))
            } else {
                setData(state => ({ ...state, [event.target.name]: false }))

            }
        }
    }

    const onFileSelect = (files, index) => {
        setFiles(files)
        ProductColor[index].productImages = files
    }

    const handleProductInfo = async (event) => {
        setApiInProgress(true)
        event.preventDefault()
        const productData = {
            id: "",
            name: data.name,
            description: data.description,
            category: { id: data.category },
            brand: { id: data.brand },
            tenantId: tenantId,
            replacement: data.replacement,
            replacementDays: data.replacementDays,
            warranty: data.warranty,
            warrantyPeriod: data.warrantyPeriod
        }
        if (update) {
            productData.id = formData.id
        }
        const response = !update ? await saveProduct(tenantId, productData) : await updateProduct(tenantId, productData); setApiInProgress(false)
        if (response && response.status === 200) {
            setApiInProgress(false)
            setProductId(response.data.id)
            !update ? toast("success", "Product Created Successfully") : toast("success", " Product Updated Successfully")
            setData(state => ({...state, btnDisabled:false}))
        } else {
            toast("error", "Something went wrong !")
        }

    }
    const handleColor = async (event, i) => {


        if (productId !== "") {
            event.preventDefault();
            showSpinner()
            const colorData = {
                id: "",
                productId: productId,
                type: ProductColor[i].type,
                value: ProductColor[i].value,
                name: ProductColor[i].name,
            }
            if (update) {
                colorData.id = ProductColor[i].colorId ? ProductColor[i].colorId : ""
            }
            let colorResponse = colorData.id === "" ? await saveColorByProduct(tenantId, productId, colorData) : await updateColorByProduct(tenantId, productId, colorData)
            if (colorResponse && colorResponse.status === 200) {
                setColorId(state => ([...state, colorResponse.data.id]))
                if (ProductColor[i].productImages && ProductColor[i].productImages.length > 0) {
                    for (let j = 0; j < ProductColor[i].productImages.length; j++) {

                        const imgData = {
                            id: "",
                            name: `${ProductColor[i].name}-img${j}`,
                            colorId: colorResponse.data.id
                        }
                        if (update) {
                            imgData.id = ProductColor[i].productImages[j].id ? ProductColor[i].productImages[j].id : ""
                            imgData.name = ProductColor[i].productImages[j].name
                            imgData.imageUrl = ProductColor[i].productImages[j].imageUrl
                        }
                        if (ProductColor[i].productImages && ProductColor[i].productImages.length > 0 && imgData.id === "") {

                            imgData.imageUrl = await uploadFile(ProductColor[i].productImages[j].file)
                        }
                        imgData.id === "" ? await saveImagebyColorProduct(tenantId, productId, colorResponse.data.id, imgData) : await updateImagebyColorProduct(tenantId, productId, colorResponse.data.id, imgData)
                    }
                }
                hideSpinner()
                !update ? toast("success", " Color Created Successfully") : toast("success", " Color Updated Successfully")
                let array = [...ProductColor];
                array[i]["btnDisabled"] = false;
                setProductColor(array)

            }
        } else {
            hideSpinner()
            toast("error", "First save the Product")
        }
    }

    const handleColorDisabledButton = (i) => {
        let array = [...ProductColor];
        array[i]["btnDisabled"] = true;
        setProductColor(array)
    }

    const handleSize = async (event, i, sizeIndex) => {

        event.preventDefault()
        if (colorId !== "" && productId !== "") {
            showSpinner()
            const skuTypeData = {
                id: "",
                name: ProductColor[i].sizes[sizeIndex].skuTypeName,
                value: ProductColor[i].sizes[sizeIndex].skuTypeValue,
                productColorId: colorId[i],
                productId: productId
            }
            if (update) {
                skuTypeData.id = ProductColor[i].sizes[sizeIndex].skuTypeId ? ProductColor[i].sizes[sizeIndex].skuTypeId : ""
            }
            const skuTypeResponse = skuTypeData.id === "" ? await saveProductSkuTypebyColorProduct(tenantId, productId, colorId[i], skuTypeData)
                : await updateProductSkuTypebyColorProduct(tenantId, productId, colorId, skuTypeData)
            if (skuTypeResponse && skuTypeResponse.status === 200) {
                const skuData = {
                    id: "",
                    productSkuTypeId: skuTypeResponse.data.id,
                    value: ProductColor[i].sizes[sizeIndex].skuValue,
                    preOrder: ProductColor[i].sizes[sizeIndex].preOrder
                }
                if (update) {
                    skuData.id = ProductColor[i].sizes[sizeIndex].skuId ? ProductColor[i].sizes[sizeIndex].skuId : ""
                    skuData.skuId = ProductColor[i].sizes[sizeIndex].skuIdA ? ProductColor[i].sizes[sizeIndex].skuIdA : ""
                }
                const skuResponse = skuData.id === "" ? await saveProductSkubyColorProduct(tenantId, productId, colorId[i], skuTypeResponse.data.id, skuData)
                    : await updateProductSkubyColorProduct(tenantId, productId, colorId[i], skuTypeResponse.data.id, skuData)

                if (skuResponse && skuResponse.status === 200) {
                    ProductColor[i].sizes[sizeIndex].skuId = skuResponse.data.id
                    const priceData = {
                        id: "",
                        isPrice: ProductColor[i].sizes[sizeIndex].isPrice,
                        wasPrice: ProductColor[i].sizes[sizeIndex].wasPrice,
                        discount: ProductColor[i].sizes[sizeIndex].discount,
                        discountType: ProductColor[i].sizes[sizeIndex].discountType,
                        productSkuId: skuResponse.data.id,
                        productId: productId
                    }
                    if (update) {
                        priceData.id = ProductColor[i].sizes[sizeIndex].priceId ? ProductColor[i].sizes[sizeIndex].priceId : ""
                    }
                    const priceResponse = priceData.id === "" ? await saveProductPricebyColorProduct(tenantId, productId, colorId[i], skuTypeResponse.id, skuResponse.id, priceData)
                        : await updateProductPriceTypebyColorProduct(tenantId, productId, colorId[i], skuTypeResponse.id, skuResponse.id, priceData)
                    console.log(priceResponse);

                    const stockData = {
                        id:"",
                        "tenantId":tenantId,
                        "productSkuId":skuResponse.data.id,
                        "actualStock": ProductColor[i].sizes[sizeIndex].skuValue,
                        "availableStock": ProductColor[i].sizes[sizeIndex].skuValue,
                        "locationId":""
                    }
                    if (update) {
                        stockData.id = ProductColor[i].sizes[sizeIndex].stockId ? ProductColor[i].sizes[sizeIndex].stockId : ""
                    }

                    const stockResponse = stockData.id === "" ? await saveStock(tenantId, stockData)
                    : await updateStock(tenantId, stockData)
                    console.log(stockResponse);

                }

            }
            hideSpinner()
            !update ? toast("success", " Size Created Successfully") : toast("success", " size Updated Successfully")
            let array = [...ProductColor];
            array[i].sizes[sizeIndex]["btnDisabled"] = false;
            setProductColor(array)
        }



    }

    const handleSaveAttribute = async (event, i, sizeIndex, attributeIndex) => {
        event.preventDefault();
        showSpinner()
        const attributeData = {
            id: "",
            label: ProductColor[i].attributes[attributeIndex].label,
            value: ProductColor[i].attributes[attributeIndex].attValue,
            productId: productId
        }
        if (update) {
            attributeData.id = ProductColor[i].attributes[attributeIndex].attributeId ? ProductColor[i].attributes[attributeIndex].attributeId : ""
        }
      const response =  attributeData.id === "" ? await saveAttributeByProduct(tenantId, productId, attributeData) : await updateAttributeByProduct(tenantId, productId, attributeData)
       console.log(response);
      hideSpinner()
        attributeData.id ==="" ? toast("success", " Attribute Created Successfully") : toast("success", " Attribute Updated Successfully")
        let array = [...ProductColor];
        array[i].attributes[attributeIndex]["disabled"] = false
        if(attributeData.id ==="")array[i].attributes[attributeIndex]["attributeId"] = response.data.id
        setProductColor(array)

    }

    const handleDeleteAttribute = async (index, sizeIndex, attributeIndex, id) => {
        showSpinner()
        const response = await deleteProductSkubyColorProduct(tenantId, productId, colorId[index], id)
        if (response && response === true) {
            handleAttributeDelete(index, sizeIndex, attributeIndex)
            hideSpinner()
            toast("success", " Attribute deleted Successfully")
        }
    }

    const handleAdd = () => {
        setProductColor(state => ([...state, { colorId: "", name: "", type: "", value: "", productImages: [], sizes: [], attributes: [], btnDisabled: true }]))
        let activeTab = document.querySelector(".gj-colorTab.active")
        let activeContent = document.querySelector(".gj-colorContent.show.active")
        activeTab.classList.remove("active")
        activeContent.classList.remove("show", "active")
        setTimeout(() => {
            document.getElementById(`colorTab${ProductColor.length + 1}`).classList.add("active")
            document.getElementById(`colorContent${ProductColor.length + 1}`).classList.add("show", "active")
        }, 500)

    }

    const handleDisableInput = (index, sizeIndex, attributeIndex) => {
        let array = [...ProductColor];
        array[index].attributes[attributeIndex]["disabled"] = true
        setProductColor(array)
    }

    const handleProductDisabled = () =>{
        setData(state => ({...state, btnDisabled:true}))

    }


    const handleAddSize = (index) => {
        let array = [...ProductColor];
        array[index]["sizes"] = [...array[index].sizes, { skuTypeId: "", label: "", attValue: "", isPrice: "", wasPrice: "", discountType: "", discount: "", skuTypeName: "", skuTypeValue: "", skuValue: "", attributes: [], preOrder:true, btnDisabled: true }]
        setProductColor(array)
        let activeTab = document.querySelector(`.gj-sizeTab${index}.active`)
        let activeContent = document.querySelector(`.gj-sizeContent${index}.show.active`)
        activeTab.classList.remove("active")
        activeContent.classList.remove("show", "active")
        setTimeout(() => {
            document.getElementById(`sizeTab${index}${ProductColor[index].sizes.length}`).classList.add("active")
            document.getElementById(`sizeContent${index}${ProductColor[index].sizes.length}`).classList.add("show", "active")
        }, 500)
    }
    const handleAddAttribute = (index, sizeIndex) => {
        let array = [...ProductColor];
        array[index]["attributes"] = [...array[index].attributes, { attributeId: "", label: "", attValue: "", disabled: true }]
        setProductColor(array)

    }

    const handleDeleteColor = async (row, index) => {
        setApiInProgress(true)
        const response = await deleteColorByProduct(tenantId, productId, row)
        if (response && response === true) {
            handleDelete(index)
            setApiInProgress(false)
            toast("success", " Color deleted Successfully")

        }
    }

    const handleDeleteSize = async (i, sizeIndex, skuTypeId) => {
        showSpinner()
        const response = await deleteProductSkuTypebyColorProduct(tenantId, productId, skuTypeId)
        if (response && response === true) {
            handleSizeDelete(i, sizeIndex)
            hideSpinner()
            toast("success", " Size deleted Successfully")
        }
    }


    const handleDelete = (index) => {
        const deleteList = [...ProductColor]
        const colors = [...colorId]
        deleteList.splice(index, 1)
        setProductColor(deleteList)
        colors.splice(index, 1)
        setColorId(colors)

    }

    const handleSizeDelete = (index, sizeIndex) => {
        const deleteList = [...ProductColor]
        deleteList[index].sizes.splice(sizeIndex, 1)
        setProductColor(deleteList)
    }

    const handleAttributeDelete = (index, sizeIndex, attributeIndex) => {
        const deleteList = [...ProductColor]
        deleteList[index].attributes.splice(attributeIndex, 1)
        setProductColor(deleteList)
    }

    const handleSelectedChange = (event, index) => {
        let array = [...ProductColor];
        const name = event.target.name
        array[index][name] = event.target.value;
        setProductColor(array)

    }
    const handleSizeChange = (event, index, secondIndex) => {
        let array = [...ProductColor];
        const name = event.target.name
        array[index].sizes[secondIndex][name] = event.target.value;
        if (name === "preOrder") {
            if (event.target.checked === true) {
                array[index].sizes[secondIndex][name] = true;
            } else {
                array[index].sizes[secondIndex][name] = false;

            }
        }
        if (name === "discountType") {
            if (array[index].sizes[secondIndex].isPrice !== "" && array[index].sizes[secondIndex].wasPrice !== "") {
                if (event.target.value === "PERCENTAGE") {
                    const value = ((array[index].sizes[secondIndex].wasPrice - array[index].sizes[secondIndex].isPrice) / array[index].sizes[secondIndex].wasPrice) * 100
                    const newArray = [...ProductColor]
                    newArray[index].sizes[secondIndex].discount = parseInt(value)
                    setProductColor(newArray)
                }
                if (event.target.value === "AMOUNT") {
                    const value = (array[index].sizes[secondIndex].wasPrice - array[index].sizes[secondIndex].isPrice)
                    const newArray = [...ProductColor]
                    newArray[index].sizes[secondIndex].discount = parseInt(value)
                    setProductColor(newArray)
                }

            }
        }
        setProductColor(array)
    }

    const handleSizeDisabledBtn = ( index, secondIndex) => {
        let array = [...ProductColor];
        array[index].sizes[secondIndex]["btnDisabled"] = true;
        setProductColor(array)
    }

    const handleAttributeChange = (event, index, secondIndex, attributeIndex) => {
        let array = [...ProductColor];
        const name = event.target.name
        array[index].attributes[attributeIndex][name] = event.target.value
        setProductColor(array)
    }



    const uploadFile = async (file) => {

        setUploadInProgress(true)
        const data = {
            tenantId,
            userId: localStorage.getItem("userId"),
            file: file
        }
        let response = await upload(getUploadApiUrl(tenantId), data)
        setUploadInProgress(false)
        if (response) {
            return response.mediaUrl;
        }
    }

    return { handleInputChange, onFileSelect, data, uploadInProgress, apiInProgress, onSave, colorType, discountType, handleAdd, ProductColor, handleDelete, handleSelectedChange, attribute, price, files, setAttribute, setPrice, handleProductInfo, handleAddAttribute, handleSizeChange, handleSizeDelete, handleColor, productId, colorId, handleSize, handleDeleteColor, handleDeleteSize, handleAddSize, handleAttributeChange,
         handleAttributeDelete, handleSaveAttribute, handleDeleteAttribute, handleDisableInput, handleColorDisabledButton, handleSizeDisabledBtn,handleProductDisabled }
}