import React from 'react';

export const toast = (status,msg) =>{
    document.getElementById("liveToast").classList.replace("hide", "show")
    document.getElementById("toast-msg").innerHTML = msg
    if(status === "success") {
        document.getElementById("toast-img").setAttribute("src","./assets/img/icons/unicons/cc-success.png")
        document.getElementById("toast-msg").style.color = "green"
    }
  if ( status === "error" ) {
        document.getElementById("toast-img").setAttribute("src","./assets/img/icons/unicons/cc-warning.png")
        document.getElementById("toast-msg").style.color = "red"
   }
    setTimeout(()=>{
     document.getElementById("liveToast").classList.replace("show", "hide")
    },4000)
}

export default function Toast(){
 return(
    <div className="position-fixed top-0 start-50 translate-middle-x p-3 alert-msg">
    <div id="liveToast" className="toast hide w-auto px-4 bg-white text-nowrap" role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-header">
        <img src="" className="rounded me-2" id="toast-img" alt="" width={20} height={20}/>
        <strong className="me-auto" id="toast-msg"></strong>
        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
  </div>
 )
}
