import React from "react";
import Card from "../components/cards/card";
import DataTable  from "react-data-table-component";
import useOrderHistory from "../hooks/useOrderHistory";
import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";
import Select from "../components/inputs/select";
import { Box } from "@mui/material";
import { handleConfirmPopup } from "../components/general/confirmDialog";


export default function OrderHistory() {
    const {list, handleClick, handleDelete, handleChange,orderList,order,setOrder, handleFdit} = useOrderHistory()
    const getDate = (num) => {
        let myDate = new Date(num);
        const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
        const date =
            myDate.getDate() + "-" + month[myDate.getMonth()===0 || myDate.getMonth()===1 ? myDate.getMonth()-1 : myDate.getMonth()] + "-" +  myDate.getFullYear();
        return date;
      };
      const columns = [
        {
          name: 'S. No',
          cell: (row, index) => index + 1,
          width: "80px",
          center: true,
        },
        {
            name: 'Order No',
            selector: row => row.orderId,
            sortable: true,
            header: <Box width={"100%"} display={"flex"} justifyContent={"center"}>Order No</Box>
        },
        {
            name: 'Date',
            selector: row => getDate(row.createdDate),
            sortable: true,
            width:"120px"
        },
        {
            name: "Payment Status",
            selector: row => <button type="button" class="btn btn-outline-info mt-3 w-100">{row.paymentStatus}</button>,
            sortable: true,
            width:"180px",
            cell: row =><Box width={"100%"} display={"flex"} justifyContent={"end"}><button type="button" class="btn btn-outline-info mt-3 w-100">{row.paymentStatus}</button></Box> ,

        },
        {
            name: "Order Status",
            selector: (row) => (
              <div>
                {row.edit === false ? (
                  row.orderStatus === "SHIPPED" ? (
                    <button type="button" className="btn btn-outline-primary mt-3 w-100">
                      {row.orderStatus}
                    </button>
                  ) : row.orderStatus === "REJECTED" ? (
                    <button type="button" className="btn btn-outline-danger mt-3 w-100">
                      {row.orderStatus}
                    </button>
                  ) : row.orderStatus === "DELIVERED" ? (
                    <button type="button" className="btn btn-outline-success mt-3 w-100">
                      {row.orderStatus}
                    </button>
                  ) : row.orderStatus === "CANCELLED" ? (
                    <button type="button" className="btn btn-outline-secondary mt-3 w-100">
                      {row.orderStatus}
                    </button>
                  ) : row.orderStatus === "ORDERED" ? (
                    <button type="button" className="btn btn-outline-primary mt-3 w-100">
                      {row.orderStatus}
                    </button>
                  ) : null
                ) : <Select  value={order} onChange={(event)=> handleChange(event,row)} label="Order Status" list={orderList} />
            }
              </div>
            ),
            width:"180px",
            cell: row =><Box width={"100%"} display={"flex"} justifyContent={"center"}> 
            <div className="w-100">
            {row.edit === false ? (
              row.orderStatus === "SHIPPED" ? (
                <button type="button" className="btn btn-outline-primary mt-3 w-100">
                  {row.orderStatus}
                </button>
              ) : row.orderStatus === "REJECTED" ? (
                <button type="button" className="btn btn-outline-danger mt-3 w-100">
                  {row.orderStatus}
                </button>
              ) : row.orderStatus === "DELIVERED" ? (
                <button type="button" className="btn btn-outline-success mt-3 w-100">
                  {row.orderStatus}
                </button>
              ) : row.orderStatus === "CANCELLED" ? (
                <button type="button" className="btn btn-outline-secondary mt-3 w-100">
                  {row.orderStatus}
                </button>
              ) : row.orderStatus === "ORDERED" ? (
                <button type="button" className="btn btn-outline-primary mt-3 w-100">
                  {row.orderStatus}
                </button>
              ) : null
            ) : <Select className={"w-100"} value={order} onChange={(event)=> handleChange(event,row)} label="Order Status" list={orderList} />
        }
          </div></Box> ,

          },
        {
            name: "Amount",
            selector: row =>row.totalAmount ,
            sortable: true,
            cell: row =><Box width={"100%"} display={"flex"} justifyContent={"end"}>{row.totalAmount}</Box> ,
            width:"100px"
        },
        {
            name: "No of Items",
            selector: row => row.totalItems,
            sortable: true,
            width:"120px",
            cell: row =><Box width={"100%"} display={"flex"} justifyContent={"start"}>{row.totalItems}</Box> ,

            
        },
        {
            name:"Edit Status",
            selector: row => <EditButton onClick={()=>{setOrder(row.orderStatus); handleFdit(row)}} />,
            width:"100px",
            cell: row =><Box width={"100%"} display={"flex"} justifyContent={"center"}><EditButton onClick={()=>{setOrder(row.orderStatus); handleFdit(row)}} /></Box> ,

        },
        {
            name:"Delete",
            selector: row => <DeleteButton  onClick={(event) =>handleConfirmPopup(event, ()=>handleDelete(row), `order "${row.orderId}"`)} />,
            width:"80px",
            center:true,

        },
        {
            name:"Explore",
            selector: row => <img className="float-right explore-btn" src="./assets/images/Arrow-Right.svg" title="Explore" alt="arrow" onClick={()=>handleClick(row)}/>,
            width:"80px",
            fixed:true,
            cell: row =><Box width={"100%"} display={"flex"} justifyContent={"center"}><img className="float-right explore-btn" src="./assets/images/Arrow-Right.svg" title="Explore" alt="arrow" onClick={()=>handleClick(row)}/></Box> ,


        }
    ];

    return(
        <> 
            <Card title="Order History" subTitle="Add/Edit/Delete your categories." className="orderHistory" modalId="masterTypeModal">
                <DataTable columns={columns}  data={list} actions pagination />  
            </Card>
    </>
    )
}