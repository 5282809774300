import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import useOrderDetail from "../hooks/useOrderDetail";
import HDiv from "../components/containers/hdiv";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from "@mui/material";
import { useNavigate } from "react-router";


export default function OrderDetail() {

    const {loading,detail} = useOrderDetail()
    const navigate = useNavigate()
  
    return(
        <>
        {loading ? <Loader /> : 
            <>
            <Card title={<HDiv className={"align-items-center gap-3"}><Box onClick={()=>navigate(-1)}><ArrowBackIcon className="cursor-pointer"/></Box><p>{`Order: ${detail.orderId}`}</p></HDiv>} subTitle="Add/Edit/Delete Category services.">
                <div className="row row-cols-lg-2 row-cols-1">
                  {detail.order !== null && detail.order.cartItems !== null && detail.order.cartItems.length > 0 && detail.order.cartItems.map((item, index) =>
                  <div className="col">
                  <Card title={`Cart-item ${index+1}`}>
                    <div className="d-flex align-items-center gap-3 w-100">
                        <div className="col" style={{width:150, height:150}}>
                             <img className="col w-100 h-100" src={item.productImage} alt="product-img" style={{objectFit:"contain"}}/>
                        </div>
                        <div className="col-7">
                            <h6 className="text-truncate">{item.productName.toUpperCase()}</h6>
                            <h5 className="text-truncate">{item.productSkuValue}</h5>
                            <span className="color-bg-detail" style={{backgroundColor: `${item.colorValue}`}} ></span>
                            <span className="d-flex gap-3"><p className="text-success">{item.isPrice}</p><p className="text-danger"><strike>{item.wasPrice}</strike></p></span>
                            <HDiv className={"justify-content-end"}>
                                {item.cartItemType === "ORDER" ? <button className="btn btn-outline-primary">{item.cartItemType}</button> : <button className="btn btn-outline-success">{item.cartItemType}</button> }
                            </HDiv>
                        </div>
                    </div>

                   </Card>
                   </div>)}
                </div>
            </Card>            
            </>
        }
    </>
    )
}