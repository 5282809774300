import React from "react";
import Card from "../components/cards/card";
import useProductDetail from "../hooks/useProductDetail";
import ProductImagePopup from "../components/popups/product-forms/product-image-form-popup";
import useProductImageForm from "../hooks/forms/useProductImageForm";
import ProductForm from "../components/popups/product-forms/product-form";
import useProduct from "../hooks/useProduct";
import useProductForm from "../hooks/forms/useProductForm";
import Loader from "../components/general/loader";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import HDiv from "../components/containers/hdiv";
import DataTable from "react-data-table-component";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router";

export default function ProductDetail() {


  const { product, imageForm, color, handleCancelClick, data, info, handleInputChange, searchValue, results, attributes, columns, searchList, handleOptionChange, handleSaveRelatedProducts, list, productsColumns } = useProductDetail()
  const { showForm, modalId, formData, handleSubmit, action, category, brand, update, loading } = useProduct()
  const navigate = useNavigate()
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  console.log(results);
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
console.log(searchValue);
  return (
    <>

      {loading ? <Loader /> :
        <>

          <Card>
            <HDiv className={"align-items-center gap-3 mb-3"} >
              <Box onClick={()=>navigate(-1)}><ArrowBackIcon className="cursor-pointer"/></Box>
              <p className="w-50 text-truncate fw-bolder">{info.name ? info.name : "Product Details"}</p>
            </HDiv>
            <ul class="nav nav-tabs align-items-center gap-2">
              <li class="nav-item nav-color align-items-center  ">
                <a class="nav-link active" data-bs-toggle="tab" href={`#productInfo`}>Product Information</a>
              </li>
              <li class="nav-item nav-color">
                <a class={`nav-link`} data-bs-toggle="tab" href={`#colors`}>Colors</a>
              </li>
              <li class="nav-item nav-color align-items-center  ">
                <a class="nav-link" data-bs-toggle="tab" href={`#attributes`}>Attributes</a>
              </li>
              <li class="nav-item nav-color align-items-center  ">
                <a class="nav-link" data-bs-toggle="tab" href={`#relatedProducts`}>Related Products</a>
              </li>
            </ul>
            <div class="tab-content p-3">
              <div class="tab-pane fade show active" id={`productInfo`}>
                <table class="table productInfo">
                  <tbody>
                    <tr><td className="col-4">Product Name </td><td className="col-8">{info.name}</td></tr>
                    <tr><td className="col-4">Brand </td><td className="col-8">{info.brand}</td></tr>
                    <tr><td className="col-4">Category </td><td className="col-8">{info.category}</td></tr>
                    <tr><td className="col-4">Product Description </td><td className="col-8 text-wrap">{info.description}</td></tr>
                  </tbody>
                </table>
              </div>
              <div className="tab-pane fade" id="colors">
                <ul class="nav nav-tabs align-items-center gap-2">
                  {product && product.length > 0 && product.map((color, index) =>
                    <li class="nav-item nav-color">
                      <a class={`nav-link d-flex gap-3 gj-colorTab ${index === 0 ? "active" : ""}`} id={`colorTab${index + 1}`} data-bs-toggle="tab" href={`#colorContent${index + 1}`}>{color.name !== "" ? color.name : "Color"}</a>
                    </li>
                  )}
                </ul>
                <div class="tab-content mt-3 p-3">
                  {product && product.length > 0 && product.map((items, index) =>
                    <div class={`tab-pane fade ${index === 0 ? "show active" : ""}`} id={`colorContent${index + 1}`}>
                      <Box >
                        <HDiv className="flex-wrap gap-3 align-items-center pt-2" >
                          {items.productImages && items.productImages.length > 0 && items.productImages.map(image =>
                            <img src={image.imageUrl} alt="ing" className="" width={100} height={100} style={{objectFit:"contain"}} />)}
                        </HDiv>
                      </Box>

                      <ul class="nav nav-tabs align-items-center mt-3 gap-2">
                        {items.sizes && items.sizes.length > 0 && items.sizes.map((size, sizeIndex) =>
                          <li class="nav-item nav-color d-flex align-items-center">
                            <a class={`nav-link d-flex gap-3 gj-sizeTab${index} ${sizeIndex === 0 ? "active" : ""}`} id={`sizeTab${index}${sizeIndex + 1}`} data-bs-toggle="tab" href={`#sizeContent${index}${sizeIndex + 1}`}>{size.skuTypeName !== "" ? size.skuTypeName : "Size"}</a>
                          </li>
                        )}
                      </ul>
                      <div class="tab-content">
                        {items.sizes.length === 0 && <div class={`tab-pane fade d-none gj-sizeContent${index} show active`} id={`sizeContent0`}></div>}
                        {items.sizes && items.sizes.length > 0 && items.sizes.map((size, sizeIndex) =>
                          <div class={`tab-pane fade gj-sizeContent${index} ${sizeIndex === 0 ? "show active" : ""}`} id={`sizeContent${index}${sizeIndex + 1}`}>
                            <Box component={"form"} >
                              <HDiv className={"mb-3 gap-3 flex-column justify-content-between text-dark pt-3 "}>
                              <table class="table productInfo fw-bold">
                                <tbody>
                                  <tr><td className="col-4">SkuType Name </td><td className="col-8">{size.skuTypeName}</td></tr>
                                  <tr><td className="col-4">SkuType Value </td><td className="col-8">{size.skuTypeValue}</td></tr>
                                  <tr><td className="col-4">Actual stock </td><td className="col-8">{size.actualStocks}</td></tr>
                                  <tr><td className="col-4">Available stock </td><td className="col-8 text-wrap">{size.availableStocks}</td></tr>
                                  <tr><td className="col-4">Old Price </td><td className="col-8">{size.wasPrice}</td></tr>
                                  <tr><td className="col-4">New Price </td><td className="col-8">{size.isPrice}</td></tr>
                                  <tr><td className="col-4">Discount </td><td className="col-8"> {size.discount}</td></tr>
                                  <tr><td className="col-4">Pre-Order </td><td className="col-8 text-wrap">{`${size.preOrder}`}</td></tr>
                                </tbody>
                              </table>
                              </HDiv>
                            </Box>

                          </div>

                        )}
                      </div>


                    </div>
                  )}
                </div>
              </div>
              <div class="tab-pane fade " id={`attributes`}>
                <table class="table productInfo">
                  <tbody>
                    {attributes && attributes.length > 0 && attributes.map((details) =>
                      <tr><td className="col-4 text-wrap">{details.label} </td><td className="col-8"> {details.value}</td></tr>
                    )}
                  </tbody>
                </table>
                <table class="product-table  prodDetTable" role="presentation">

                  <tbody style={{ fontSize: "14px", textAlign: "left" }} >
                    {attributes && attributes.length > 0 && attributes.map((details, index) => (
                      <tr>  <td class="a-color-secondary a-size-base prodDetSectionEntry"> </td>
                        <td class="a-size-base prodDetAttrValue">
                        </td> </tr>))}
                  </tbody>
                </table>
              </div>
              <div class="tab-pane fade" id={`relatedProducts`}>
                <Autocomplete
                 freeSolo
                 id="free-solo-2-demo"
                 disableClearable
                 className="mb-3"
                  options={results.map((option) => option.name)}
                  onChange={handleOptionChange}
                  inputValue={searchValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search product"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search', 
                      }}
                      onChange={(event) => handleInputChange(event)}
                      
                    />
                  )}
                />
                {searchList.length > 0 && <> <Typography marginTop={"24px"} fontSize={18}>Selected Products</Typography>
                  <DataTable columns={columns} data={searchList} />
                  <HDiv className={"justify-content-end"}>
                    <button className="btn btn-primary" onClick={(event) => handleSaveRelatedProducts(event)}>Save</button>
                  </HDiv></>}
                <div className="col-6">
                  <Typography marginTop={"24px"} fontSize={18}>Related Products</Typography>
                  <DataTable columns={productsColumns} data={list} />
                </div>

              </div>
            </div>
          </Card>
          {showForm && <ProductForm hook={useProductForm} action={action} id={modalId} onCancel={handleCancelClick} category={category} brand={brand} onSave={handleSubmit} formData={formData} title={"Update"} update={update} showBackNavigation />}
          {imageForm && <ProductImagePopup hook={useProductImageForm} action={action} onCancel={handleCancelClick} onSave={handleSubmit} color={color} formData={data} title="Edit Image" update={update} />}
        </>
      }
    </>
  )
}