import { useEffect, useState } from "react";
import { saveTopBrand, updateTopBrand } from "../../service/TopBrandService";
import { useParams } from "react-router";
import { toast } from "../../components/general/alert";
import { getBrands } from "../../service/BrandService";

const useTopBrandsForm = ({
  onSave,
  formData,
  update,
  showForm,
  setShowForm,
}) => {
  const [list, setList] = useState([]);
  // const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const { tenantId } = useParams();
  const newData = {
    brand: "",
  };
  // const [data, setData] = useState({brand:""});

  const [data, setData] = useState(
    formData !== undefined
      ? { ...formData, tenantId: tenantId }
      : { ...newData }
  ); 

  const [apiInProgress, setApiInProgress] = useState(false);

  const handleInputChange = (e) => {
    setData({...data,[e.target.name]:e.target.value});
  };

  const fetchAll = async () => {
    const response = await getBrands(tenantId);
    let array = [];
    for (let i = 0; i < response.length; i++) {
      if (response[i].deleted === false) {
        array.push({ name: response[i].name, key: response[i].id });
      }
    }
    setList(array);
    setLoading(false);
  };

  const handleSubmit = async (saveAndAddAnother) => {
    const body = {
      tenantId: tenantId,
      brandId: data.brandId,
    };

    const datas = {
      id: formData.id,
      tenantId: tenantId,
      brandId: data.brandId,
    };
    if (update) data.id = formData.id;
    const response = !update
      ? await saveTopBrand(tenantId, body)
      : await updateTopBrand(tenantId, datas);
    !update
      ? toast("success", "Created Successfully")
      : toast("success", "Updated Successfully");
    setShowForm(false);
    setApiInProgress(false);
    if (onSave) {
      onSave(response, saveAndAddAnother);
    }
    setData({});
    //  update ? setData(data.brand):"";
    // fetchAll()
  };

  useEffect(() => {
    fetchAll();
  }, [showForm]);
  return {
    handleInputChange,
    data,
    setData,
    handleSubmit,
    list,
    showForm,
    apiInProgress,
    loading
  };
};

export default useTopBrandsForm;
