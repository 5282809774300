import { 
    getBannersApiUrl,
    getBannerApiUrl,
    getSaveBannerApiUrl
 } from "./ApiUrls";
import { post, get, put, Delete } from "./Service";


export const getBanners = async (tenantId) => {
    const response = await get(getBannersApiUrl(tenantId));
    if(!response) {
        return []
    }
    return response;
};

export const getBanner = async (tenantId,bannerId) => {
    const response = await get(getBannerApiUrl(tenantId,bannerId));
    if(!response) {
        return []
    }
    return response;
};

export const saveBanner = async (tenantId, data) => {
    const response = await post(getSaveBannerApiUrl(tenantId), data);
    return response;
};

export const updateBanner = async (tenantId, data) => {
    const response = await put(getSaveBannerApiUrl(tenantId), data);
    return response;
};

export const deleteBanner = async (tenantId, key) => {
    const response = await Delete(getBannerApiUrl(tenantId, key));
    return response;
};