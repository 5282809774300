import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { deleteBrand, getBrands } from "../service/BrandService"
import { toast } from "../components/general/alert"


export default function useBrand() {

    const modalId = "brandModal"

    const [list, setList] = useState([])

    const [formData, setFormData] = useState(undefined)

    const [action, setAction] = useState("POST")

    const [loading, setLoading] = useState(true)

    const { tenantId } = useParams()

    const [showForm, setShowForm] = useState(false)

    const [ update, setUpdate] = useState(true)

    const getHeaders = () => {
        const headers = [
            {title: "Name", type: "label", property: "name"},
            {title: "Description", type: "label", property: "description"},
            {title: "Action", type: "action"}
        ]
        return headers;
    }

    const handleSubmit = async (response, saveAndAddAnother) => {
        if(!saveAndAddAnother) {
            setShowForm(false)
        }
        if(response){
            setShowForm(false)
        }
        fetchAll()
    }

    const fetchAll = async () => {
        const response = await getBrands(tenantId)
        let array = []
        for(let i=0; i<response.length ; i++){
            if(response[i].deleted ===false ){
               array.push({...response[i]}) 
            }
        }
        setList(array)
        setLoading(false)
    }

    const handleClick = (data) => {
        // navigate(getBrandDetailUrl(tenantId, data.id))
    }

    const handleAddClick = () => {
        setUpdate(false)
        setShowForm(true)
        setFormData({})
    }

    const handleEditClick = (row) => {
        console.log(row)
        setFormData(row)
        setShowForm(true)
        setUpdate(true)
    }

    const handleDeleteClick = async(row) => {
        const response = await deleteBrand(tenantId,row.id)
        if(response && response === true){
            toast("success","Deleted Successfully")
        }
        fetchAll()
        console.log(response);
    }

    const handleCancelClick = () => {
        setShowForm(false)
    }

    useEffect(() => {
        fetchAll()
    }, [ showForm])

    return {showForm, modalId, list, formData, getHeaders, handleSubmit, action, loading, handleAddClick, handleClick, handleEditClick, handleDeleteClick, handleCancelClick, update,setAction}

}