import React from "react";

function MenuLink({data,onClick,index}) {
    return(
        <li className="nav-item menu">
          <a href={data.link} className={`nav-link  ${data.active ? 'active' : ''}`} id={`menuItem${index}`} onClick={(event)=>onClick(event)} >
            <i className={`icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center tf-icons bx ${data.icon}`}></i>
            <span className="nav-link-text ms-1">{data.title}</span>
          </a>
        </li>
    )
}

function MenuHeader({data}) {
    return(
        <li className="nav-item">
          <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder mt-3"><strong>{data.title}</strong></h6>
        </li>
    )
}

export default function MenuItem({data,onClick,index}) {
    return(
        <>{data.type === 'menu' ? <MenuLink data={data} onClick={onClick} index={index}  /> : <MenuHeader data={data} />}</>
    )
}