export const getCategoryDetailUrl = (tenantId, categoryId) => {
    return `/t/${tenantId}/c/${categoryId}`
}

export const getBrandDetailUrl = (tenantId, brandId) => {
    return `/t/${tenantId}/b/${brandId}`
}

export const getBannerDetailUrl = (tenantId, bannerId) => {
    return `/t/${tenantId}/banner/${bannerId}`
}

export const getOrderDetailUrl = (tenantId,orderId) => {
    return `/t/${tenantId}/order/${orderId}`
}

export const getProductDetailUrl = (tenantId, productId) => {
    return `/t/${tenantId}/p/${productId}`
}


export const getFloorDetailUrl = (tenantId, locationId, floorId) => {
    return `/t/${tenantId}/l/${locationId}/floor/${floorId}`
}


export const getDealProductsUrl = (tenantId, dealId) => {
    return `/t/${tenantId}/d/${dealId}`
}


