import React from "react";
import FormPopup from "./form-popup";
import InputGroup from "../inputs/input-group";
import ProcessLoader from "../general/process-loader";
import { Autocomplete, TextField } from "@mui/material";
import SelectGroup from "../inputs/select-group";

export default function DealProductPopup({ title, onSave, onCancel, formData, hook, update }) {
    const { handleInputChange, handleSubmit, data, apiInProgress, results, handleOptionChange, searchValue, handleChange, product, colorIndex, setColorIndex, sizeIndex, setSizeIndex, handlePrice, discountType } = hook({ onSave, formData, update });
    return (
        <FormPopup title={title} onSave={handleSubmit} onCancel={onCancel} update={update}>
            <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                className="mb-3"
                options={results.map((option) => option.name)}
                onChange={handleOptionChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Product"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                        onChange={(event) => handleChange(event)}
                        value={searchValue}
                        required
                    />

                )}
            />
            {colorIndex >= 0 && <div className="d-flex flex-column gap-3" ><div className="d-flex gap-2 overflow-auto flex-nowrap align-items-center">
                <p>Colors :</p>
                {product.colors && product.colors.length > 0 && product.colors.map((color, index) =>
                    <div key={index} className={`d-flex gap-1 border border-success rounded-circle ${colorIndex >= 0 && index === colorIndex ? " border-primary" : "border-white"}`} >
                        <span style={{ "backgroundColor": `${color.color.value}`, width: "20px", height: "20px", borderRadius: "50%" }} onClick={() => { setColorIndex(index); setSizeIndex(0); handlePrice(0) }} ></span>
                    </div>
                )}
            </div>
                <div className="d-flex gap-2 overflow-auto flex-nowrap align-items-center">
                    <p>Type : </p>
                    {product && colorIndex >= 0 && <div className="btn-group d-flex gap-2 overflow-height align-items-center  flex-nowrap overflow-auto" role="group" aria-label="Basic radio toggle button group">
                        {product.colors[colorIndex].productSkuTypeDetail && product.colors[colorIndex].productSkuTypeDetail.length > 0 && product.colors[colorIndex].productSkuTypeDetail.map((size, index) =>
                            <div key={index} className="text-nowrap">
                                <input type="radio" className="btn-check p-2" name="btnradio" id={`btnradio${index + 1}`} autocomplete="off" onClick={(event) => { setSizeIndex(index); }} checked={sizeIndex === index && true} />
                                <label className="btn btn-outline-primary border d-flex flex-column m-0 justify-content-center px-2 fw-medium" for={`btnradio${index + 1}`}>
                                    <span className="">  {size.productSkuType.value}</span>
                                </label>
                            </div>)}
                    </div>}
                </div>

                <InputGroup value={data.isPrice} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="New Price*" name="isPrice" type="text" placeHolder="Enter New Price" required />
                <InputGroup value={data.wasPrice} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Old Price*" name="wasPrice" type="text" placeHolder="Enter Old Price" required />
                <SelectGroup value={data.discountType} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Discount Type*" name="discountType" list={discountType} placeHolder="Discount Type" required />
                <InputGroup value={data.discount} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Discount*" name="discount" type="text" placeHolder="Enter Discount" required />
            </div>
            }
            {apiInProgress && <ProcessLoader />}
        </FormPopup>
    )
}