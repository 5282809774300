import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import useCategoryDetail from "../hooks/useCategoryDetail";
import useCategoryForm from "../hooks/forms/useCategoryForm";
import CategoryPopup from "../components/popups/category-form-popup";
import { Box } from "@mui/material";
import HDiv from "../components/containers/hdiv";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router";
import DeleteButton from "../components/inputs/delete-button";
import EditButton from "../components/inputs/edit-button";
import DataTable from "react-data-table-component";
import { handleConfirmPopup } from "../components/general/confirmDialog";

export default function CategoryDetail() {

    const {showForm, formData, handleSubmit, category, loading, handleAddClick, handleEditClick, handleDeleteClick, handleCancelClick,action, modalId, dropdown, update} = useCategoryDetail()
    const navigate = useNavigate()
    const columns = [
        {
            name: 'S. No',
            cell: (row, index) => index + 1,
            width: "80px",
            center: true,
          },
        {
            name: "Image",
            selector: row => <img className="object-fit-contain" src={row.imageUrl} alt="" width={50} height={50} style={{ objectFit: "contain" }} />,
            sortable: true,
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"start"}><img className="object-fit-contain" src={row.imageUrl} alt="" width={50} height={50} style={{ objectFit: "contain" }} /></Box>,
            width: "100px"
        },
        {
            name: 'Subcategory Name',
            selector: row => row.name,
            sortable: true,
            header: <Box width={"100%"} display={"flex"} justifyContent={"center"}>Name</Box>
        },

        {
            name: "Edit",
            selector: row => <EditButton onClick={() => { handleEditClick(row) }} />,
            width: "80px",
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"center"}><EditButton onClick={() => { handleEditClick(row) }} /></Box>,

        },
        {
            name: "Delete",
            selector: row => <DeleteButton onClick={(event) =>handleConfirmPopup(event, ()=>handleDeleteClick(row), `subcategory "${row.name}"`)} />,
            width: "80px",
           center:true

        },
    ];
    return(
        <>
                     <Card title={<HDiv className={"align-items-center gap-3"}><Box onClick={()=>navigate(-1)}><ArrowBackIcon className="cursor-pointer"/></Box><p>Subcategories</p></HDiv>} showAddButton  addLabel="Add Subcategory" onAdd={handleAddClick}>      
        {loading ? <Loader /> :                 <DataTable columns={columns}  data={category} actions pagination />  
 }  
                     </Card>
            {showForm && <CategoryPopup hook={useCategoryForm} action={action} id={modalId} onCancel={handleCancelClick} onSave={handleSubmit} listDisabled formData={formData} dropdown={dropdown} title={ update ?` Edit ${formData.name}` : "Add Subcategory"}  update={update} /> }
            
        
    </>
    )
}