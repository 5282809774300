import React from 'react'

export default function Card({className, children, style, onClick, title,onHover, modalId, showAddButton, onAdd,addLabel,anotherLabel,onAddAnother,showAnotherButton}) {
    return (
        <div className={`card  ${className ? className : ''}`} style={style ? style : {}} onClick={onClick} title={onHover}>
            {title &&
                <div className="card-header pb-0 p-3">
                    <div className="row">
                        <div className="col-md-8 d-flex align-items-center">
                        <h6 className="mb-0">{title}</h6>
                        </div>
                        <div className="col-md-4 text-end d-flex gap-3 justify-content-end">
                         {showAddButton &&   <button type="button" className="btn btn-primary pull-right " onClick={() => onAdd()}>{addLabel}</button> }
                         {showAnotherButton &&   <button type="button" className="btn btn-primary pull-right" onClick={() => onAddAnother()}>{anotherLabel}</button> }
                        </div>
                    </div>
                 
                </div>
            }
            <div className='card-body position-relative'>
               
                {children}</div>
        </div>
    )
}