import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "../components/general/alert";
import {
  deleteTopBrand,
  getTopBrandsByTenantId,
} from "../service/TopBrandService";

export default function useTopBrands() {
  const modalId = "CategoryModal";

  const [list, setList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(undefined);

  const [action, setAction] = useState("POST");

  const { tenantId } = useParams();

  const [showForm, setShowForm] = useState(false);

  const [update, setUpdate] = useState(false);

  const handleSubmit = async (response, saveAndAddAnother) => {
    if (!saveAndAddAnother) {
      setShowForm(false);
    }
    if (response) {
      setShowForm(false);
    }
    fetchAll();
  };
  let exists;
  const fetchAll = async () => {
    const response = await getTopBrandsByTenantId(tenantId);
    let array = [];
    for (let i = 0; i < response.length; i++) {
      if (response[i].deleted === false) {
          const { brandId } = response[i];
      // Check if the brandId already exists in the array
       exists = array.some(item => item.brandId === brandId);
      if (!exists) {
        array.push({ ...response[i] });
      }
      }
    }
    setList(array);
    setLoading(false);
  };


  const handleAddClick = () => {
    setShowForm(true);
    setFormData({});
    setUpdate(false)
  };

  const handleEditClick = (row) => {
    setFormData(row);
    setShowForm(true);
    setUpdate(true);
  };

  const handleDeleteClick = async (row) => {
    
    const response = await deleteTopBrand(tenantId, row.id);
    if (response && response === true) {
      toast("success", "Deleted Successfully");
    }
    fetchAll();
  };

  const handleCancelClick = () => {
    setShowForm(false);
  };

  useEffect(() => {
    fetchAll();
  }, [showForm]);

  return {
    showForm,
    setShowForm,
    modalId,
    list,
    formData,
    action,
    loading,
    handleAddClick,
    handleEditClick,
    handleDeleteClick,
    update,
    setAction,
    handleCancelClick,
    handleSubmit,
  };
}
