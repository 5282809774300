import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  updateNewArrivals,
  saveNewArrivals,
} from "../../service/NewArrivalsService";
import { toast } from "../../components/general/alert";
import { getProductsByName } from "../../service/productService";

export default function useNewArrivalsForm({ id, onSave, formData, update }) {
  const { tenantId } = useParams();

  const [results, setResults] = useState([])

  const [searchValue, setSearchValue] = useState("")

  const newData = { id: "", tenantId };
  const [data, setData] = useState(
    formData !== undefined
      ? { ...formData, tenantId: tenantId }
      : { ...newData }
  );

  const [apiInProgress, setApiInProgress] = useState(false);

  const handleInputChange = (event) => {
    event.stopPropagation();
    setData((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChange = async (event) => {
    setSearchValue(event.target.value)
    setResults([])
    if (event.target.value.length > 0) {

      const response = await getProductsByName(tenantId, event.target.value)
      console.log(response);
      setResults(response)
    }else{
      setResults([])
    }


  }

  const handleSubmit = async (saveAndAddAnother) => {

    if("productId" in data){
    setApiInProgress(true);

    if (update) data.id = formData.id;
    let datas = {
      tenantId: tenantId,
      productId: {
        id: data.productId,
      },
    };

    let UpdateData = {
      tenantId: tenantId,
      id: formData.id,
      productId: {
        id: data.productId,
      },
    };

    const response = !update
      ? await saveNewArrivals(tenantId, datas)
      : await updateNewArrivals(tenantId, UpdateData);
    !update
      ? toast(`${response.error ?"error": "success"}`,`${response.error ?"Already exists": "Created Successfully"}` )
      : toast("success", "Updated Successfully");

    setApiInProgress(false);
    if (onSave) {
      onSave(response, saveAndAddAnother);
    }
    setData({});}else{
      toast("error","Select the vaild product")
    }
  };

  const getSelectedOption = (value) => {
    return results.find((option) => option.name === value) || null;
  };

  const handleOptionChange = async (event, value) => {
    const selectedOption = getSelectedOption(value);
    setData(state => ({ ...state, productId: selectedOption.id }))
  };

  return { handleInputChange, handleSubmit, data, apiInProgress, handleChange, handleOptionChange, searchValue, results };
}
