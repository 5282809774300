import React from "react";
import HDiv from "../components/containers/hdiv"
// import Text from "../components/elements/texts/text"
import VDiv from "../components/containers/vdiv"

import Button from "../components/inputs/button"
import InputGroup from "../components/inputs/input-group"
import useLogin from "../hooks/useLogin"
import Form from "../components/containers/form"

export default function Login() {
    const { handleLogin, setPassword, setUserName, handleEye } = useLogin()

    return(
        <HDiv className="page-form-container d-xl-flex d-block">
            <VDiv className="page-form-banner col-xl-6 col-12">
                <VDiv className="sliders">
                    <img className="object-fit-contain" src="./assets/images/login_image.png" alt="login" width="100%" height="100%" />
                </VDiv>
            </VDiv>
            <VDiv className="col-xl-6 col-12 d-flex justify-content-center align-items-xl-center align-items-end  bg-white login-form">
            <Form title="Sign In" subTitle="Enter your email and password to sign in" onSubmit={(event)=>{event.preventDefault();handleLogin()}}> 
                <InputGroup className="mb-3 col-md-12" enabled label="Enter your username" name="email-username" onChange={(event) => setUserName(event.target.value)} type="text" placeHolder={"Email"} required />
                <VDiv className="position-relative w-100 p-0">
                    <InputGroup className="mb-3 col-md-12" enabled label="Enter your password" name="password" id="togglepassword" onChange={(event) => setPassword(event.target.value)} type="password" placeHolder={"********"} required />
                    <i
              class="eye-icon far fa-eye-slash"
              id="eye"
              onClick={(event) => handleEye(event)}
            ></i>
                </VDiv>
                {/* <HDiv className="flex mb-3 no-padding">
                    <label className="edu-checkbox"><input type="checkbox"/> Remember me</label>
                    <HGrow />
                    <Text text="Forgot password?" className="edu-link underline" />
                </HDiv> */}
                <Button className="mb-3 col-md-12 default_btn" label="Sign in" type="submit" />
            </Form>
            </VDiv>
        </HDiv>
    )
}