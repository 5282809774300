import { Modal, Box, Typography, Grid } from "@mui/material";
import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import PopUp from "./confirmDialog";

const MuiPopUp = ({ open, handleClose, children, titles }) => {
  return (
    <>
    
      <Grid container>
        <Grid item xs={4} sm={4}>
          <Modal open={open} >
        
            <Box
              sx={{
               width:"100%",
                height:"100vh",
                top: "50%",
                left: "50%",
                bgcolor: "background.paper",
                position: "absolute",
                transform: "translate(-50%, -50%)",
                borderRadius: "8px",
                overflowY:"scroll",display:"block"
              }}
              paddingBottom={"2%"}
            >
                  <PopUp/>
              <Box padding={"2%"} sx={{ cursor: "pointer", borderTopRightRadius: "8px", borderTopLeftRadius:"8px", position:"sticky", top:"0", backgroundColor:"white",zIndex:100 }} display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                <ul class="nav nav-tabs align-items-center gap-2">
                    {titles && titles.length > 0 && titles.map((title,index)=>
                        <li class="nav-item nav-color d-flex align-items-center">
                            <a class={`nav-link ${index === 0 ? "active":"" }`} id={`headerTab${index+1}`} data-bs-toggle="tab" href={`#headerContent${index+1}`}>{title}</a>
                        </li>
                    )}
                </ul>
                <Typography onClick={handleClose} pt={1} color={"red"}>
                  <CancelIcon sx={{backgroundColor:"white",borderRadius:"20px"}} />
                </Typography>
              </Box>
              <Box px={"2%"} sx={{overflowY:"auto",display:"block"}}>
                {children}
            </Box>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </>
  );
};

export default MuiPopUp;
