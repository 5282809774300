
import { getDealProductUrl, getDealProductsByDealUrl, saveDealProductUrl } from "./ApiUrls";
import { Delete, Put, get, post } from "./Service";

export const getDealProducts = async (tenantId,dealId) => {
    const response = await get(getDealProductsByDealUrl(tenantId, dealId));
    if(!response) {
        return []
    }
    return response;
};

export const getDealProduct = async (tenantId,id) => {
    const response = await get(getDealProductUrl(tenantId,id));
    if(!response) {
        return []
    }
    return response;
};

export const saveDealProduct = async (tenantId, data) => {
    const response = await post(saveDealProductUrl(tenantId), data);
    return response;
};

export const updateDealProduct = async (tenantId, data) => {
    const response = await Put(saveDealProductUrl(tenantId), data);
    return response;
};

export const deleteDealProduct = async (tenantId, key) => {
    const response = await Delete(getDealProductUrl(tenantId, key));
    return response;
};