import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"


export default function useMenu() {

    const [items, setItems] = useState([])
    const { tenantId } = useParams();
    const [show, setShow] = useState(true)
    const handleSidebar = () => {
        document.querySelector(".g-sidenav-show").classList.remove("g-sidenav-pinned")
        setShow(true)
    }


    const generateMenus = (masterTypes) => {
        let items = []
        items.push({ id: "menuItem0", title: "Dashboard", link: `#/t/${tenantId}/`, icon: "bx-home-circle", type: "menu", active: true })
        items.push({ id: "menuItem", title: "Masters", type: "menu-header" })
        items.push({ id: "menuItem1", title: "Categories", link: `#/t/${tenantId}/c`, icon: "bx-category", type: "menu", active: false })
        items.push({ id: "menuItem2", title: "Brands", link: `#/t/${tenantId}/b`, icon: "bx-unite", type: "menu", active: false })
        items.push({ id: "menuItem3", title: "Top Brands", link: `#/t/${tenantId}/tb`, icon: "bxl-unity", type: "menu", active: false })
        items.push({ id: "menuItem4", title: "Deals", link: `#/t/${tenantId}/d`, icon: "bxs-discount", type: "menu", active: false })
        items.push({ id: "menuItem5", title: "Banners", link: `#/t/${tenantId}/banner`, icon: "bx-card", type: "menu", active: false })
        items.push({ id: "menuItem6", title: "Products", link: `#/t/${tenantId}/p`, icon: "bx-detail", type: "menu", active: false })
        items.push({ id: "menuItem7", title: "New Arrivals", link: `#/t/${tenantId}/n`, icon: "bx-news", type: "menu", active: false })
        items.push({ id: "menuItem", title: "Order", type: "menu-header" })
        items.push({ id: "menuItem8", title: "Order History", link: `#/t/${tenantId}/orderhistory`, icon: "bx-history", type: "menu", active: false })

        if (localStorage.getItem("menuName") !== null) {
            for (let i = 0; i < items.length; i++) {
                if(items[i].active === true){
                    items[i].active = false
                }
            }
            for (let i = 0; i < items.length; i++) {
                if (`#/${window.location.href.split("/#/")[1]}` === items[i].link) {
                    items[i].active = true
                    localStorage.setItem("menuName", items[i].id)
                } else if (`#/${window.location.href.split("/#/")[1]}`.includes("/c/") || `#/${window.location.href.split("/#/")[1]}`.includes("/d/") || `#/${window.location.href.split("/#/")[1]}`.includes("/p/") || `#/${window.location.href.split("/#/")[1]}`.includes("/order/")) {
                    if (localStorage.getItem("menuName") === items[i].id) {
                        items[i].active = true
                    }
                } else {
                    items[i].active = false
                }
            }
        }
        setItems(items)
    }

    useEffect(() => {
        const newArr = [...items]
        for (let j = 0; j < newArr.length; j++) {

            if (`#/${window.location.href.split("/#/")[1]}` === newArr[j].link) {
                newArr[j].active = true
                localStorage.setItem("menuName", newArr[j].id)
            } else if (`#/${window.location.href.split("/#/")[1]}`.includes("/c/") || `#/${window.location.href.split("/#/")[1]}`.includes("/d/") || `#/${window.location.href.split("/#/")[1]}`.includes("/p/") || `#/${window.location.href.split("/#/")[1]}`.includes("/order/")) {
                if (localStorage.getItem("menuName") === newArr[j].id) {
                    newArr[j].active = true
                }
            }
            else {
                newArr[j].active = false
            }
        }
        setItems(newArr)

    }, [window.location.href])

    useEffect(() => {
        generateMenus()

    }, [])

    const handleScroll = (event) => {
        localStorage.setItem("menuName", event.target.id)
    }

    return { items, handleSidebar, show, setShow, handleScroll }
}