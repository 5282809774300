import { 
    getAllNewArrivals,deleteNewArrivals,getUpdateNewArrivalApiUrl,getSaveNewArrivalUrl
 } from "./NewArrivals";
import { post, get, put, Delete } from "./Service";


export const getBrands = async (tenantId) => {
    const response = await get(getAllNewArrivals(tenantId));
    if(!response) {
        return []
    }
    return response;
};



export const saveNewArrivals = async (tenantId, data) => {
    const response = await post(getSaveNewArrivalUrl(tenantId), data);
    return response;
};

export const updateNewArrivals = async (tenantId, data) => {
    
    const response = await put(getUpdateNewArrivalApiUrl(tenantId), data);
    return response;
};

export const deleteBrand = async (tenantId, key) => {
    const response = await Delete(deleteNewArrivals(tenantId, key));
    return response;
};