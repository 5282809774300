import React from "react";
import FormPopup from "./form-popup";
import InputGroup from "../inputs/input-group";
import TextArea from "../inputs/textarea";
import FileSelector from "../inputs/file-selector";
import ProcessLoader from "../general/process-loader";
import SelectGroup from "../inputs/select-group";

export default function CategoryPopup({id, title, onSave, onCancel, formData, hook, dropdown, listDisabled, update}) {
    const { handleInputChange, handleSubmit, onFileSelect, data, uploadInProgress, apiInProgress} = hook({onSave, formData, update});
    return(
        <FormPopup title={title} onSave={handleSubmit} onCancel={onCancel} update={update}>
            <SelectGroup label="Categories" className="mb-3 col-md-12" placeHolder="Categories" value={data.parentCategoryId} list={dropdown} enabled={!listDisabled}  onChange={handleInputChange} name="parentCategoryId" required/>
            <InputGroup value={data.name} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Name*" name="name"  type="text" placeHolder="Enter name" required />
            <TextArea value={data.description} className="mb-3 col-md-12" onChange={handleInputChange} label="Description" name="description" placeHolder="Enter description"  />
            <FileSelector onFileSelect={onFileSelect} value={data.imageUrl} label="Upload Image" showProgress={uploadInProgress} update={update} required/>
            {apiInProgress && <ProcessLoader />}
        </FormPopup>
    )
}