import { 
    getProductApiUrl,
    getSaveProductApiUrl,
    getProductsApiUrl,
    getSkutypesByColorId,
    getSkuBySkuTypeId,
    getProductDetailApiUrl,
    searchProducts,
    saveRelatedProductsUrl,
    getRelatedProductsUrl,
    deleteRelatedProductsUrl
 } from "./ApiUrls";
import { get,  Delete, Post, Put, Get } from "./Service";


export const getProducts = async (tenantId) => {
    const response = await get(getProductsApiUrl(tenantId));
    if(!response) {
        return []
    }
    return response;
};

export const getProduct = async (tenantId,productId) => {
    const response = await get(getProductApiUrl(tenantId,productId));
    if(!response) {
        return []
    }
    return response;
};

export const getProductDetail = async (tenantId,productId) => {
    const response = await get(getProductDetailApiUrl(tenantId,productId));
    if(!response) {
        return null
    }
    return response;
};
export const saveProduct = async (tenantId, data) => {
    const response = await Post(getSaveProductApiUrl(tenantId), data);
    return response;
};

export const updateProduct = async (tenantId, data) => {
    const response = await Put(getSaveProductApiUrl(tenantId), data);
    return response;
};

export const deleteProduct = async (tenantId, key) => {
    const response = await Delete(getProductApiUrl(tenantId, key));
    return response;
};

export const getSkutypesByColor = async (tenantId,productId,colorId) => {
    try{
        const response = await get(getSkutypesByColorId(tenantId,productId,colorId));
        return response;
    }catch (err){
        console.log(err);
    }
   
  
};

export const getSkuBySkuType = async (tenantId,productId,colorId,skuTypeId) => {
    const response = await get(getSkuBySkuTypeId(tenantId,productId,colorId,skuTypeId));
    if(!response) {
        return []
    }
    return response;
};

export const getProductsByName = async (tenantId,name) => {
    const response = await get(searchProducts(tenantId,name));
    if(!response) {
        return []
    }
    return response;
};

export const saveRelatedProducts = async (tenantId,data) => {
    const response = await Post(saveRelatedProductsUrl(tenantId),data);
    if(!response) {
        return []
    }
    return response;
};

export const getAllRelatedProducts = async (tenantId,id) => {
    const response = await Get(getRelatedProductsUrl(tenantId,id));
    if(!response) {
        return []
    }
    return response;
};

export const delteRelatedProducts = async (tenantId,id) => {
    const response = await Delete(deleteRelatedProductsUrl(tenantId,id));
    return response;
};