import React from "react";

export default function Select({ className, enabled = true, label, name, value, type, onChange, placeHolder, list ,required }) {

    return(
        <select className="form-control form-select text-input ecom-select " disabled={!enabled} name={name} onChange={(event) => onChange(event)} type={type} value={value} placeholder={placeHolder} required={required}>
                <option value="">Select {placeHolder}</option>
                {list && list.map((item) => <option key={item.key} value={item.key}>{item.name}</option>)}
            </select>
    )
}