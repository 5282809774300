import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Login } from "../service/LoginAPI";
import { toast } from "../components/general/alert";


export default function useLogin() {
    const navigate = useNavigate();
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleLoginResponse = (response) => {
        if (response) {
            localStorage.setItem("token", response.access_token);
            localStorage.setItem("firstName", response.firstName);
            localStorage.setItem("lastName", response.lastName);
            localStorage.setItem("userId", response.id);
            localStorage.setItem("email", response.email);
            localStorage.setItem("tenantId", response.tenantId);
            localStorage.setItem("locationId", response.locationId);
            if (response.access_token) {
                navigate(`/t/${response.tenantId}/`);
            }
        }
    };

    const handleLogin = async () => {
        const formData = new FormData();
        formData.append("grant_type", "password");
        formData.append("username", userName.toLowerCase());
        formData.append("password", password);
        localStorage.clear();
        const response = await Login(formData);
        console.log(response);
        if (response && response.data && response.status === 200) {
            toast("success","Login Successful")
         
            handleLoginResponse(response.data);
        }
        if (response && response.response && response.response.status === 401) {
            toast("error","Please Enter Valid Crendentials")
        }

    };

    
  const handleEye = () => {
    const element = document.getElementById("togglepassword");
    const eye = document.getElementById("eye");
    if (element.type === "password") {
      element.type = "text";
      eye.classList.remove("fa-eye-slash");
      eye.classList.add("fa-eye");
    } else {
      element.type = "password";
      eye.classList.remove("fa-eye");
      eye.classList.add("fa-eye-slash");
    }
  };

    return { handleLogin, setPassword, setUserName, handleEye };
}
