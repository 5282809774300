import { useEffect, useState } from "react"
import { deleteDealProduct, getDealProducts } from "../service/DealProductService"
import { toast } from "../components/general/alert"
import { useParams } from "react-router"
import { getProductDetail } from "../service/productService"
import { getProductPricebyColorProduct } from "../service/productPriceService"


export default function useDealProducts(){

    const [list, setList] = useState([])

    const [showForm, setShowForm] = useState(false)

    const [priceForm, setPriceForm] = useState(false)

    const [priceFormData, setPriceFormData] = useState({})

    const [update, setUpdate] = useState(false)

    const [formData, setFormData] = useState({})

    const [loading, setLoading] = useState(true)

    const { tenantId, dealId} = useParams()

    const getAllDealProducts = async () => {
        const response = await getDealProducts(tenantId, dealId)
        console.log(response)
        let list = []
        if(response && response.length >0){
            for(let i=0; i<response.length; i++){
                if(response[i].deleted === false){
                const res = await getProductDetail(tenantId, response[i].productId)
                list.push({...response[i],product:res})
                }
            }
        }
        console.log(list)
        setList(list)
        setLoading(false)
    }

    const handleSubmit = async (response, saveAndAddAnother) => {
        if(!saveAndAddAnother) {
            setShowForm(false)
        }
        if(response){
            setShowForm(false)
            setPriceForm(false)
        }
        getAllDealProducts()
    }
    useEffect(() => {
        getAllDealProducts()
    }, [showForm])

    const handleForm = () => {
        setUpdate(false)
        setShowForm(true)
    }

    const handleCancelForm = () => {
        setShowForm(false)
        setPriceForm(false)

    }

    const reverseDate = (date) =>{
        return `${new Date(date).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }).split("/").reverse().join("-")}`
    }

    const handleEdit = async(row) => {
        console.log(row);
        setUpdate(true)
        setPriceForm(true)
        const price =  await getProductPricebyColorProduct(tenantId, row.productId,null,null,null,row.priceId)
        console.log(price);
        setPriceFormData({id:price.id, isPrice:price.isPrice, wasPrice:price.wasPrice , discount:price.discount, discountType: price.discountType, productSkuId: price.productSkuId})
        setFormData({id:row.id,title:row.title,description:row.description, startDate:reverseDate(row.startDate),endDate:reverseDate(row.endDate),deleted:false})
    }

    const handleDelete = async (data) => {
        const response = await deleteDealProduct(tenantId, data.id)
        if (response) {
            toast("success", "Deleted successfully")
            getAllDealProducts()
        }
    }

    return{
        list,
        showForm,
        update,
        formData,
        handleForm,
        handleCancelForm,
        handleDelete,
        handleEdit,
        reverseDate,
        handleSubmit,
        loading,
        priceForm,
        priceFormData
    }
}