import { useState, useEffect, } from "react"
import React from "react"
import { useParams } from "react-router-dom"
import { deleteImagebyColorProduct } from "../service/productImageService"
import { delteRelatedProducts, getAllRelatedProducts, getProductDetail, getProductsByName, saveRelatedProducts } from "../service/productService"
import { toast } from "../components/general/alert"
import DeleteButton from "../components/inputs/delete-button"
import { getStock } from "../service/StockService"

export default function useProductDetail() {

    let modalId = "productDetail"

    let imageId = "imageDetail"
    const [product, setProduct] = useState([])

    const [data, setData] = useState(undefined)

    const [loading, setLoading] = useState(true)

    const { tenantId, productId } = useParams()

    const [showForm, setShowForm] = useState(false)

    const [imageForm, setImageForm] = useState(false)

    const [skuTypeForm, setSkuTypeForm] = useState(false)

    const [skuForm, setSkuForm] = useState(false)

    const [priceForm, setPriceForm] = useState(false)

    const [values, setValues] = useState({})

    const [update, setUpdate] = useState(true)

    const [color, setColor] = useState([])

    const [info, setInfo] = useState({})

    const [searchValue, setSearchValue] = useState("")

    const [results, setResults] = useState([])

    const [attributes, setAttributes] = useState([])

    const [searchList, setSearchList] = useState([])

    const [list, setList] = useState([])

    const handleInputChange = async (event) => {
        setSearchValue(event.target.value)
        if (event.target.value.length > 0) {
            setResults([])
            const response = await getProductsByName(tenantId, event.target.value)
            console.log(response);
            setResults(response)
        }else if (event.target.value.length === 0){
            setResults([])
        }


    }
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            fixed: "left",
            width: "300px"
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            width: "350px",

        },
        {
            name: "Category",
            selector: row => row.category,
            sortable: true,
            width: "150px"
        },
        {
            name: "Brand",
            selector: row => row.brand,
            sortable: true,
            width: "100px"
        },
        {
            name: "Delete",
            selector: row => <DeleteButton onClick={() => handleDelete(row)} />,
            width: "80px"
        },

    ];
    const productsColumns = [
        {
            name: 'Name',
            selector: row => row.relatedProductName,
            sortable: true,
            fixed: "left",
        },
        {
            name: "Delete",
            selector: row => <DeleteButton onClick={() => deleteRelatedProduct(row)} />,
        },

    ];
    const handleDelete = (index) => {
        console.log(index)
        const array = [...searchList]
        const selectedIndex = array.indexOf(index);
        array.splice(selectedIndex, 1)
        setSearchList(array)
    }
    const handleSubmit = async ({ response, saveAndAddAnother, imgResponse, skuTypeResponse, skuResponse, priceResponse }) => {
        if (!saveAndAddAnother) {
            setShowForm(false)
        }
        if (response) {
            setShowForm(false)
            setImageForm(true)
            setValues({ colorId: response.id })
        }
        if (imgResponse) {
            console.log(imgResponse)
            setImageForm(false)
            fetchProduct()
        }
        if (skuTypeResponse && skuTypeResponse.id) {
            console.log(skuTypeResponse)
            setSkuTypeForm(false)
            setSkuForm(true)
            setValues(state => ({ ...state, skuTypeId: skuTypeResponse.id }))
        }
        if (skuResponse && skuResponse.id) {
            console.log(skuResponse)
            setSkuForm(false)
            setPriceForm(true)
            setValues(state => ({ ...state, skuId: skuResponse.id }))
        }
        if (priceResponse && priceResponse.id) {
            setPriceForm(false)
            setShowForm(true)
        }
        fetchProduct()
    }

    const getSelectedOption = (value) => {
        return results.find((option) => option.name === value) || null;
    };

    const handleOptionChange = (event, value) => {
        const selectedOption = getSelectedOption(value);
        setSearchValue("")
        if(selectedOption.id){
            setSearchList(state => ([...state, { "name": selectedOption["name"], description: selectedOption.description, category: selectedOption.category !== null ? selectedOption.category.name : "", categoryId: selectedOption.category !== null ? selectedOption.category.id : "", brand: selectedOption.brand !== null ? selectedOption.brand.name : "", brandId: selectedOption.brand !== null ? selectedOption.brand.id : "", id: selectedOption.id, }]))
        }
    };

    const handleSaveRelatedProducts = async (event) => {
        event.preventDefault();
        const realtedProducts = searchList.map(state => ({ id: state.id }))
        const payload = {
            productId: productId,
            relatedProduct: realtedProducts
        }
        const response = await saveRelatedProducts(tenantId, payload)
        if (response && response.status === 200) {
            toast("success", "Saved Successfull")
            getRelatedProduct()
            setSearchList([])
        }
    }

    const deleteRelatedProduct = async (row) => {
        const response = await delteRelatedProducts(tenantId, row.id)
        if (response) {
            toast('success', 'Deleted Successfully')
            getRelatedProduct()
        }
    }

    const getRelatedProduct = async () => {
        const response = await getAllRelatedProducts(tenantId, productId)
        console.log(response);
        if (response && response.status === 200 && response.data.length > 0) {
            const activeProducts = response.data.filter(item => item.deleted === false)
            setList(activeProducts)
        }
    }


    const fetchProduct = async () => {
        const response = await getProductDetail(tenantId, productId)
        console.log(response);
        setInfo({ name: response.product.name, brand: response.product.brand.name, category: response.product.category.name, description: response.product.description })
        let attributes = []
        if (response.product.attributes !== null && response.product.attributes.length > 0) {
            for (let q = 0; q < response.product.attributes.length; q++) {
                if (response.product.attributes[q].deleted === false) {
                    attributes.push({ ...response.product.attributes[q] })
                }
            }
        }
        setAttributes(attributes)
        let colors = []
        let color = []
        if (response.colors !== null) {
            if (response.colors.length > 0) {
                for (let i = 0; i < response.colors.length; i++) {
                    if (response.colors[i].color.deleted === false) {
                        color.push(response.colors[i].color.id)
                        let sizes = []
                        if (response.colors[i].productSkuTypeDetail.length > 0) {
                            for (let j = 0; j < response.colors[i].productSkuTypeDetail.length; j++) {
                                if (response.colors[i].productSkuTypeDetail[j].productSkuType.deleted === false) {
                                    let skuType = response.colors[i].productSkuTypeDetail[j].productSkuType
                                    let sku = response.colors[i].productSkuTypeDetail[j].productSku
                                    let resp;
                                    console.log(sku);
                                    if(Object.keys(sku[0]).includes("id")){
                                         resp = await getStock(tenantId,sku[0].id )
                                    }
                                    sizes.push({ priceId: sku.length > 0 && sku[0].productPrice !== null ? sku[0].productPrice.id : "", isPrice: sku.length > 0 && sku[0].productPrice !== null ? sku[0].productPrice.isPrice : "", wasPrice: sku.length > 0 && sku[0].productPrice !== null ? sku[0].productPrice.wasPrice : "", discountType: sku.length > 0 && sku[0].productPrice !== null ? sku[0].productPrice.discountType : "", discount: sku.length > 0 && sku[0].productPrice !== null ? sku[0].productPrice.discount : "", skuTypeId: skuType.id, skuTypeName: skuType.name, skuTypeValue: skuType.value, skuValue: sku.length > 0 ? sku[0].value : "", skuId: sku.length > 0 ? sku[0].id : "", skuIdA: sku.length > 0 ? sku[0].skuId : "", preOrder:sku.length > 0 ?sku[0].preOrder : "", stockId: Object.keys(resp).length !== 0 ? resp.id : "", btnDisabled:false, availableStocks: Object.keys(resp).length !== 0 ? resp.availableStock : 0, actualStocks: Object.keys(resp).length !== 0 ? resp.actualStock : 0 })
                                }
                            }
                        }
                        colors.push({ colorId: response.colors[i].color.id, name: response.colors[i].color.name, type: response.colors[i].color.type, value: response.colors[i].color.value, productImages: response.colors[i].color.productImages, sizes: sizes, attributes: attributes })
                    }
                }
            }
            setProduct(colors)
        } else {
            setProduct([])
        }

    }
    const handleAddClick = () => {
        setUpdate(false)
        setShowForm(true)
        setData({})
    }

    const handleEditClick = (row) => {
        console.log(row)
        setData(row)
        setShowForm(true)
        setUpdate(true)
    }
    const handleImageEditClick = (row) => {
        console.log(row)
        setData(row)
        setImageForm(true)
        setUpdate(true)
    }
    const handlePriceEditClick = (row) => {
        console.log(row)
        setData(row)
        setPriceForm(true)
        setUpdate(true)
    }
    const handleDeleteClick = async (row) => {
        // const response = await deleteCategory(tenantId,row.id)
        // console.log(response)
        // fetchCategory()
    }

    const handleImageDeleteClick = async (row) => {
        console.log(row);
        const response = await deleteImagebyColorProduct(tenantId, productId, row.colorId, row.id)
        if (response && response === true) {
            toast("success", "Deleted Successfully")
        }
        fetchProduct()
    }

    const handleCancelClick = () => {
        setShowForm(false)
        setImageForm(false)
        setSkuTypeForm(false)
        setSkuForm(false)
        setPriceForm(false)
    }

    useEffect(() => {
        fetchProduct()
        getRelatedProduct()
    }, [showForm])
    console.log(product);


    return { showForm, data, handleSubmit, product, loading, handleAddClick, handleEditClick, handleDeleteClick, handleCancelClick, modalId, imageForm, values, imageId, skuTypeForm, skuForm, priceForm, setImageForm, setSkuTypeForm, setSkuForm, setPriceForm, update, handleImageEditClick, handleImageDeleteClick, setUpdate, color, handlePriceEditClick, info, handleInputChange, searchValue, results, setLoading, setColor, attributes, columns, searchList, handleOptionChange, handleSaveRelatedProducts, list, productsColumns }

}