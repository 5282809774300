import React from "react";

export default function InputGroup({ className, enabled = true, label, name, value, type, onChange, placeHolder, required, id, min, max }) {

    return (
        <div className={className + " edc-input-group"} >
            <label className="form-label">{label}</label>
            <input className="form-control text-input" id={id} disabled={!enabled} name={name} onChange={(event) => onChange(event)} type={type} value={value} placeholder={placeHolder} required={required} min={min} max={max} />
        </div>
    );
}
