import { BASE_E_COMMERCE_URL } from "./ApiUrls"

export const getAllNewArrivals = (tenantId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/new-arrivals/products`
}
export const deleteNewArrivals = (tenantId,productId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/new-arrivals/${productId}`
}
export const getUpdateNewArrivalApiUrl = (tenantId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/new-arrivals`
}
export const getSaveNewArrivalUrl = (tenantId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/new-arrivals`
}