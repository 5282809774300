import React from "react";
import FormPopup from "../form-popup";
import InputGroup from "../../inputs/input-group";
import ProcessLoader from "../../general/process-loader";
import SelectGroup from "../../inputs/select-group";

export default function ProductPricePopup({id, title, onSave, onCancel, formData, hook, values,update}) {
    const { handleInputChange, handleSubmit,  data,  apiInProgress,discountType} = hook({onSave, formData,values,update});
    return(
        <FormPopup title={title} onSave={handleSubmit} onCancel={onCancel} update={update}>
            <InputGroup value={data.isPrice} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="New Price*" name="isPrice"  type="text" placeHolder="Enter New Price" required />
            <InputGroup value={data.wasPrice} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Old Price*" name="wasPrice"  type="text" placeHolder="Enter Old Price" required />
            <SelectGroup value={data.discountType} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Discount Type*" name="discountType" list={discountType} placeHolder="Enter Discount Type" required />
            <InputGroup value={data.discount} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Discount*" name="discount"  type="text" placeHolder="Enter Discount" required />
            {apiInProgress && <ProcessLoader />}
        </FormPopup>
    )
}