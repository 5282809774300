import React from "react";
import SelectGroup from "../inputs/select-group";
import FormPopup from "./form-popup";
import ProcessLoader from "../general/process-loader";

const SelectGroupPopup = ({
  title,
  onSave,
  onCancel,
  formData,
  hook,
  nameRequired,
  fileRequired,
  update,
  showForm,
  setShowForm,
}) => {
    
    const { handleInputChange, data,  handleSubmit, list ,apiInProgress} = hook({
    onSave,
    formData,
    update,
    showForm,
    setShowForm,
  });

  return (
    <FormPopup
      title={title}
      onSave={handleSubmit}
      onCancel={onCancel}
      update={update}
    >
      <SelectGroup
        list={list}
        value={data.brandId}
        onChange={handleInputChange}
        name="brandId"
        label="Brand"
        required
      />

      {apiInProgress && <ProcessLoader />}
    </FormPopup>
  );
};

export default SelectGroupPopup;
