import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Categories from "./pages/Categories";
import CategoryDetail from "./pages/CategoryDetail";
import Brand from "./pages/Brand";
import Banner from "./pages/Banner";
import Product from "./pages/Product";
import ProductDetail from "./pages/ProductDetail";
import OrderHistory from "./pages/OrderHistory";
import OrderDetail from "./pages/OrderDetail";
import Layout from "./components/layout/layout";
import TopBrands from "./pages/TopBrands";
import NewArrivals from "./pages/NewArrivals";
import Deals from "./pages/Deals";
import DealProducts from "./pages/DealProducts";
 

function App() { 
    return (
        <HashRouter>
          <Routes>
              <Route element={<Login />} path="/" />
              <Route element={<Layout><Dashboard /></Layout>} path="/t/:tenantId/" />
              <Route element={<Layout><Brand /></Layout>} path="/t/:tenantId/b" />
              <Route element={<Layout><Categories /></Layout>} path="/t/:tenantId/c" />
              <Route element={<Layout><Banner /></Layout>} path="/t/:tenantId/banner" />
              <Route element={<Layout><Product /></Layout>} path="/t/:tenantId/p" />
              <Route element={<Layout><CategoryDetail /></Layout>} path="/t/:tenantId/c/:categoryId" />
              <Route element={<Layout><ProductDetail /></Layout>} path="/t/:tenantId/p/:productId" />
              <Route element={<Layout><OrderHistory /></Layout>} path="/t/:tenantId/orderhistory" />
              <Route element={<Layout><OrderDetail /></Layout>} path="/t/:tenantId/order/:orderId" />
              <Route element={<Layout>< NewArrivals/></Layout>} path="/t/:tenantId/n" />
              <Route element={<Layout><TopBrands /></Layout>} path="/t/:tenantId/tb" />
              <Route element={<Layout><Deals /></Layout>} path="/t/:tenantId/d" />
              <Route element={<Layout><DealProducts /></Layout>} path="/t/:tenantId/d/:dealId" />

          </Routes>
        </HashRouter>
    );
 }

export default App;

