import React from 'react'
import VDiv from './vdiv'
import PagePopupHeader from '../general/page-popup-header'

export default function PagePopup({showHeader, showBackNavigation, title, subTitle, onBack, children, customHeader, className,update}) {

    return (
        <VDiv className="page-popup">
            <VDiv className="popup" style={{ backgroundImage: update?"url('./assets/images/edit.jpg')"  :"url('./assets/images/sliders/login-slider-1.jpg')"}}>
                {showHeader ? <PagePopupHeader goToPrevPage={false} title={title} subTitle={subTitle} showBackNavigation={showBackNavigation} onBack={onBack} /> : null}
                {customHeader ? customHeader : null}
                <VDiv className={`popup-content ${className ? className : ''}`} >
                    {children}
                </VDiv>
            </VDiv>
        </VDiv>
    )
}