import React from "react";
import FormPopup from "./form-popup";
import InputGroup from "../inputs/input-group";
import TextArea from "../inputs/textarea";
import ProcessLoader from "../general/process-loader";

export default function DealsPopup({title, onSave, onCancel, formData, hook, update}) {
    const { handleInputChange, handleSubmit,  data,  apiInProgress} = hook({onSave, formData, update});
    return(
        <FormPopup title={title} onSave={handleSubmit} onCancel={onCancel} update={update}>
            <InputGroup value={data.title} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Title*" name="title"  type="text" placeHolder="Enter title" required />
            <TextArea value={data.description} className="mb-3 col-md-12" onChange={handleInputChange} label="Description" name="description" placeHolder="Enter description"  />
            
            {!update ?<><InputGroup value={data.startDate} className="mb-3 col-md-12" onChange={handleInputChange} min={new Date().toJSON().slice(0, 10)} enabled label="Start date*" name="startDate"  type="date" placeHolder="Enter name" required />
            <InputGroup value={data.endDate} className="mb-3 col-md-12" onChange={handleInputChange} min={data.startDate} enabled label="End date*" name="endDate"  type="date" placeHolder="Enter name" required /></> :
           <><InputGroup value={data.startDate} className="mb-3 col-md-12" onChange={handleInputChange} min={data.startDate} enabled label="Start date*" name="startDate"  type="date" placeHolder="Enter name" required />
            <InputGroup value={data.endDate} className="mb-3 col-md-12" onChange={handleInputChange} min={data.startDate}  enabled label="End date*" name="endDate"  type="date" placeHolder="Enter name" required /></> }
            {apiInProgress && <ProcessLoader />}
        </FormPopup>
    )
}