import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import SelectGroupPopup from "../components/popups/selectgroup-popup";
import useTopBrands from "../hooks/useTopBrands";
import useTopBrandsForm from "../hooks/forms/useTopBrandsForm";
import { Box } from "@mui/material";
import DeleteButton from "../components/inputs/delete-button";
import DataTable from "react-data-table-component";
import { handleConfirmPopup } from "../components/general/confirmDialog";

export default function TopBrands() {


    const { showForm, setShowForm, modalId, list, formData, action, loading, handleAddClick, handleDeleteClick, handleCancelClick, update } = useTopBrands()
    const columns = [
        {
            name: 'S. No',
            cell: (row, index) => index + 1,
            width: "80px",
            center: true,
          },
        {
            name: "Image",
            selector: row => <Box><img className="object-fit-contain" src={row.imageUrl} alt="" width={50} height={50} style={{ objectFit: "contain" }} /></Box>,
            sortable: true,
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"start"}><img className="object-fit-contain" src={row.imageUrl} alt="" width={50} height={50} style={{ objectFit: "contain" }} /></Box>,
            width: "100px"
        },
        {
            name: 'Top Brand Name',
            selector: row => row.name,
            sortable: true,
            header: <Box width={"100%"} display={"flex"} justifyContent={"center"}>Name</Box>
        },
        {
            name: "Delete",
            selector: row => <DeleteButton onClick={(event) =>handleConfirmPopup(event, ()=>handleDeleteClick(row), `top-brand "${row.name}"`)}/>,
            width: "80px",
            center:true

        },
    ];
    return (
        <>
            <Card title="Top Brands" subTitle="Add/Edit/Delete your top brands." modalId="masterTypeModal" showAddButton addLabel="Add Top Brand" onAdd={handleAddClick} >
                {loading ? <Loader /> : <DataTable columns={columns} data={list} actions pagination />}
            </Card>
            {showForm && <SelectGroupPopup hook={useTopBrandsForm} action={action} id={modalId} onCancel={handleCancelClick} title={update ? `Edit ${formData.name}` : "Add Top Brand"} update={update} formData={formData} showForm={showForm} setShowForm={setShowForm} className="mb-3 col-md-12" />}
        </>
    )
}