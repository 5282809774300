import React from "react"
import useDeals from "../hooks/useDeals"
import Card from "../components/cards/card"
import DealsPopup from "../components/popups/deals-popup"
import useDealsForm from "../hooks/forms/useDealsForm"
import EditButton from "../components/inputs/edit-button"
import DeleteButton from "../components/inputs/delete-button"
import Loader from "../components/general/loader"
import { Box } from "@mui/system"
import DataTable from "react-data-table-component"
import { handleConfirmPopup } from "../components/general/confirmDialog"

export default function Deals(){

    const {list, showForm, update, formData, handleSubmit, handleCancelForm, handleForm,handleDelete, handleEdit, reverseDate, handleClick, loading} = useDeals()
    const columns = [
        {
            name: 'S. No',
            cell: (row, index) => index + 1,
            width: "80px",
            center: true,
          },
        {
            name: 'Deal Name',
            selector: row => row.title,
            sortable: true,
            width:"200px",
            header: <Box width={"100%"} display={"flex"} justifyContent={"Start"}>Name</Box>
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            width:"300px",
            header: <Box width={"100%"} display={"flex"} justifyContent={"start"}>Description</Box>
        },
        {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
            width:"150px",
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"start"}>{reverseDate(row.startDate)}</Box>
        },
        {
            name: 'End Date',
            selector: row => row.endDate,
            sortable: true,
            width:"150px",
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"start"}>{reverseDate(row.endDate)}</Box>
        },
        {
            name: "Edit",
            selector: row => <EditButton onClick={() => { handleEdit(row) }} />,
            width: "80px",
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"center"}><EditButton onClick={() => { handleEdit(row) }} /></Box>,

        },
        {
            name: "Delete",
            selector: row => <DeleteButton  onClick={(event) =>handleConfirmPopup(event, ()=>handleDelete(row), `deal "${row.title}"`)} />,
            width: "80px",
            center:true
        },
        {
            name: "Explore",
            selector: row => <img className="float-right explore-btn" src="./assets/images/Arrow-Right.svg" title="Explore" alt="arrow" onClick={() => handleClick(row)} />,
            width: "80px",
            fixed: true,
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"center"}><img className="float-right explore-btn" src="./assets/images/Arrow-Right.svg" title="Explore" alt="arrow" onClick={() => handleClick(row)} /></Box>,
        }
    ];
   
    return(
        <>
       
            <Card title="Deals" subTitle="Add/Edit/Delete your categories." modalId="masterTypeModal" showAddButton addLabel="Add Deal" onAdd={handleForm} >
            {loading ? <Loader /> : 
                // <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-xl-2 g-3">
                //     {list && list.length > 0 ? list.map((data) => 
                //     <div key={data.key} className="col cursor-pointer box rounded-3 position-relative">
                //         <div className="deals-tile shadow-sm p-3 rounded-3 position-relative" style={{height:"150px", backgroundColor:"#cb0c9f"}} onClick={()=>handleClick(data)} >
                //         <div className="custom-shape-divider-top-1692010913">
                //             <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                //                 <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                //             </svg>
                //         </div>
                //             <div className="d-flex flex-column justify-content-center text-white h-100" >
                //                 <p className="fs-6 fw-bolder text-truncate">{data.title}</p>
                //                 <p className="fs-6 text-truncate">{data.description}</p>
                //                 <div className="d-flex justify-content-between">
                //                         <div>
                //                             <p>Start date :</p>
                //                             <span className="fw-bolder">{reverseDate(data.startDate)}</span>
                //                         </div>
                //                         <div>
                //                             <p>End date :</p>
                //                             <span className="fw-bolder">{reverseDate(data.endDate)}</span>
                //                         </div>
                //                 </div>
                //             </div>  
                //         </div>
                //         <div className='d-flex actions justify-content-end' style={{"background": "linear-gradient(to right bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2))" }}>
                //             <EditButton onClick={()=>handleEdit(data)} />
                //             <DeleteButton className="ml-2" onClick={()=>handleDelete(data)}/>
                //         </div>
                //         </div>
                //     ) : <NoDataMessage modalId="masterTypeModal"/>}
                // </div>}
                <DataTable columns={columns} data={list} actions pagination />}

            </Card>
            {showForm && <DealsPopup hook={useDealsForm} onSave={handleSubmit}  onCancel={handleCancelForm} fileRequired formData={formData} title={update ? `Edit Deal`:"Add Deal"} update={update}  /> }
        </>
    )
}