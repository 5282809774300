import { 
    getImagesbyColorProductApiUrl,
    getImageBycolorProductApiUrl,
    getSaveImageByColorProductApiUrl
 } from "./ApiUrls";
import { get, Delete, Post, Put } from "./Service";


export const getImagesbyColorProduct = async (tenantId, productId,colorId) => {
    const response = await get(getImagesbyColorProductApiUrl(tenantId, productId,colorId));
    if(!response) {
        return []
    }
    return response;
};

export const getImagebyColorProduct = async (tenantId, productId,colorId) => {
    const response = await get(getImageBycolorProductApiUrl(tenantId, productId,colorId));
    if(!response) {
        return []
    }
    return response;
};

export const saveImagebyColorProduct = async (tenantId, productId,colorId, data) => {
    const response = await Post(getSaveImageByColorProductApiUrl(tenantId, productId,colorId), data);
    return response;
};

export const updateImagebyColorProduct = async (tenantId, productId,colorId, data) => {
    const response = await Put(getSaveImageByColorProductApiUrl(tenantId, productId,colorId), data);
    return response;
};

export const deleteImagebyColorProduct = async (tenantId, productId,colorId, key) => {
    const response = await Delete(getImageBycolorProductApiUrl(tenantId, productId,colorId, key));
    return response;
};