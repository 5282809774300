import { 
    getColorsByProductApiUrl,
    getColorByProductApiUrl,
    getSaveColorByProductApiUrl,
 } from "./ApiUrls";
import { get,Delete, Post, Put } from "./Service";


export const getColorsByProduct = async (tenantId,productId) => {
    const response = await get(getColorsByProductApiUrl(tenantId, productId));
    if(!response) {
        return []
    }
    return response;
};

export const getColorByProduct = async (tenantId,productId,colorId) => {
    const response = await get(getColorByProductApiUrl(tenantId,productId,colorId));
    if(!response) {
        return []
    }
    return response;
};

export const saveColorByProduct = async (tenantId,productId, data) => {
    const response = await Post(getSaveColorByProductApiUrl(tenantId, productId), data);
    return response;
};

export const updateColorByProduct = async (tenantId,productId, data) => {
    const response = await Put(getSaveColorByProductApiUrl(tenantId, productId), data);
    return response;
};

export const deleteColorByProduct = async (tenantId, productId, data) => {
    const response = await Delete(getColorByProductApiUrl(tenantId, productId,data));
    return response;
};