import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import useBrandForm from "../hooks/forms/useBrandForm";
import NameDescriptionPopup from "../components/popups/name-description-form-popup";
import useBrand from "../hooks/useBrand"
import { Box } from "@mui/system";
import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";
import DataTable from "react-data-table-component";
import { handleConfirmPopup } from "../components/general/confirmDialog";

export default function Brand() {

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) => index + 1,
            width: "80px",
            center: true,
          },
        {
            name: "Image",
            selector: row => <Box><img className="object-fit-contain" src={row.imageUrl} alt="" width={50} height={50} style={{ objectFit: "contain" }} /></Box>,
            sortable: true,
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"start"}><img className="object-fit-contain" src={row.imageUrl} alt="" width={50} height={50} style={{ objectFit: "contain" }} /></Box>,
            width: "100px"
        },
        {
            name: 'Brand Name',
            selector: row => row.name,
            sortable: true,
            header: <Box width={"100%"} display={"flex"} justifyContent={"center"}>Name</Box>
        },
        {
            name: "Edit",
            selector: row => <EditButton onClick={() => { handleEditClick(row) }} />,
            width: "80px",
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"center"}><EditButton onClick={() => { handleEditClick(row) }} /></Box>,

        },
        {
            name: "Delete",
            selector: row => <DeleteButton onClick={(event) =>handleConfirmPopup(event, ()=>handleDeleteClick(row), `brand "${row.name}"`)} />,
            width: "80px",
            center:true

        },
    ];

    const { showForm, modalId, list, formData, handleSubmit, action, loading, handleAddClick, handleEditClick, handleDeleteClick, handleCancelClick, update } = useBrand()
    return (
        <>
            <Card title="Brands" subTitle="Add/Edit/Delete your categories." modalId="masterTypeModal" showAddButton addLabel="Add Brand" onAdd={handleAddClick} >
                {loading ? <Loader /> :
                    <DataTable columns={columns} data={list} actions pagination />}
            </Card>
            {showForm && <NameDescriptionPopup hook={useBrandForm} action={action} id={modalId} onCancel={handleCancelClick} onSave={handleSubmit} nameRequired fileRequired formData={formData} title={update ? `Edit ${formData.name}` : "Add Brand"} update={update} />}
        </>
    )
}