import { 
    getAttributesbyProductApiUrl,
    getAttributebyProductApiUrl,
    SaveAttributebyProductApiUrl,
 } from "./ApiUrls";
import { get, Delete, Post, Put } from "./Service";


export const getAttributesByProduct = async (tenantId,productId) => {
    const response = await get(getAttributesbyProductApiUrl(tenantId, productId));
    if(!response) {
        return []
    }
    return response;
};

export const getAttributeByProduct = async (tenantId,productId,attributeId) => {
    const response = await get(getAttributebyProductApiUrl(tenantId,productId,attributeId));
    if(!response) {
        return []
    }
    return response;
};

export const saveAttributeByProduct = async (tenantId,productId, data) => {
    const response = await Post(SaveAttributebyProductApiUrl(tenantId, productId), data);
    return response;
};

export const updateAttributeByProduct = async (tenantId,productId, data) => {
    const response = await Put(SaveAttributebyProductApiUrl(tenantId, productId), data);
    return response;
};

export const deleteAttributeByProduct = async (tenantId, productId, key) => {
    const response = await Delete(getAttributebyProductApiUrl(tenantId, productId), key);
    return response;
};