import VDiv from "../../containers/vdiv";
import React from "react";
import HDiv from "../../containers/hdiv";
import SelectGroup from "../../inputs/select-group";
import InputGroup from "../../inputs/input-group";
import PagePopupHeader from "../../general/page-popup-header";
import ProcessLoader from "../../general/process-loader";
import ImageSelector from "../../inputs/image-selector";
import DeleteButton from "../../inputs/delete-button";


export default function ProductForm({update,onSave,onCancel,hook,formData,title,showBackNavigation,category,brand}){
    const { handleInputChange, handleSubmit, onFileSelect, data, uploadInProgress, apiInProgress, discountType, colorType,ProductColor,handleDelete,handleAdd,handleSelectedChange} = hook({onSave, formData, update});
   
    return(
        <VDiv className="page-popup">
            <VDiv className="popup product-pop">
            <PagePopupHeader goToPrevPage={false} title={title} showBackNavigation={showBackNavigation} onBack={onCancel} />
                <VDiv className={`popup-content mt-5 top-5`} >
                <form className='form needs-validation' onSubmit={(event) =>{event.preventDefault(); handleSubmit()}}>
                    <h6 className="pb-0 mb-0">Product Information</h6>
                    <HDiv className="flex-xl-nowrap gap-3 flex-wrap" >
                        <InputGroup value={data.name} className="mb-3 col-xl col-12 col-lg-5 col-md-5 flex-grow-1" onChange={handleInputChange} enabled label="Product Name*" name="name"  type="text" placeHolder="Enter name" required/>
                        <SelectGroup label="Brand*" className="mb-3 col-xl col-12 col-lg-5 col-md-5 selectBrand flex-grow-1" placeHolder="Brand" value={data.brand} list={brand} onChange={handleInputChange} name="brand" required />
                        <SelectGroup label="Categories*" className="mb-3 col-xl col-12 col-lg-5 col-md-5 flex-grow-1" placeHolder="Categories" value={data.category} list={category}  onChange={handleInputChange} name="category" required={true} />
                        <InputGroup value={data.description} className="mb-3 col-xl col-12 col-lg-5 col-md-5 flex-grow-1" onChange={handleInputChange} enabled label="Description*" name="description"  type="text" placeHolder="Enter description" required />
                    </HDiv>
                    <h6 className="pb-0 mb-0">Product Color Details</h6>
                    <table className="table">
                        <tbody>
                    {ProductColor && ProductColor.length > 0 && ProductColor.map((items,index)=>

                        <tr key={index}className="row align-items-center">
                            <h6 className="pb-0 mb-0">{`Color ${index + 1}`}</h6>
                            <td className="col-xl-3"><InputGroup value={items.name}  onChange={(event)=>handleSelectedChange(event,index)} enabled label="Color Name*" name="name"  type="text" placeHolder="Enter name" required /></td>
                            <td className="col-xl-3"><SelectGroup value={items.type}  onChange={(event)=>handleSelectedChange(event,index)} enabled label="Color Type*" list={colorType} name="type" placeHolder="Color Type" required /></td>
                            <td className="col-xl-3">
                            <div className={" edc-input-group position-relative"} >
                                <label className="form-label">Color*</label>
                                <HDiv>
                                <input className="form-control text-input w-75" name="value" placeholder="Pick color"  onChange={(event)=>handleSelectedChange(event,index)} type={"text"} value={items.value} required />
                                <input type="color" name="value"   onChange={(event)=>handleSelectedChange(event,index)} value={items.value} class="form-control text-input color-select"/>
                                </HDiv>
                            </div>
                            </td>
                         {index !==0 && <td className="col-xl-3 justify-self-center"><DeleteButton className="ml-2" onClick={()=>handleDelete(index)}/></td>}
                            <h6 className="pb-0 mb-0">{`Add Images for color ${index + 1}`}</h6>
                            <td className="w-auto"><ImageSelector  onFileSelect={onFileSelect} showProgress={uploadInProgress} multiple value={items.productImages !== null ? items.productImages : []} update={update} index={index} required /></td>
                            <h6 className="pb-0 mb-0">Attributes</h6>
                            <td  className="col-xl-3"><InputGroup value={ items.label}  onChange={(event)=>handleSelectedChange(event,index)}  enabled label="Attribute Name*" name="label"  type="text" placeHolder="Enter Name" required /> </td>
                            <td  className="col-xl-3">  <InputGroup value={items.attValue} onChange={(event)=>handleSelectedChange(event,index)}  enabled label="Attribute Value*" name="attValue"  type="text" placeHolder="Enter Value" required /></td>
                            <h6 className="pb-0 mb-0">Stock</h6>
                            <td className="col-xl-3"><InputGroup value={items.skuTypeName} onChange={(event)=>handleSelectedChange(event,index)} enabled label="Stock-Type Name*" name="skuTypeName"  type="text" placeHolder="Enter name" required /></td>
                            <td className="col-xl-3"><InputGroup value={items.skuTypeValue}  onChange={(event)=>handleSelectedChange(event,index)} enabled label="Stock-Type Value*" name="skuTypeValue"  type="text" placeHolder="Enter value" required /></td>
                            <td className="col-xl-3"><InputGroup value={items.skuValue} onChange={(event)=>handleSelectedChange(event,index)} enabled label="Stock*" name="skuValue"  type="text" placeHolder="Enter value" required /></td>
                            <h6 className="pb-0 mb-0">Price</h6>
                            <td className="col-xl-3"><InputGroup value={items.isPrice} onChange={(event)=>handleSelectedChange(event,index)} enabled label="New Price*" name="isPrice"  type="text" placeHolder="Enter New Price" required /></td>
                            <td className="col-xl-3"><InputGroup value={items.wasPrice} onChange={(event)=>handleSelectedChange(event,index)} enabled label="Old Price*" name="wasPrice"  type="text" placeHolder="Enter Old Price" required /></td>
                            <td className="col-xl-3"><SelectGroup value={items.discountType}  list={discountType} onChange={(event)=>handleSelectedChange(event,index)} enabled label="Discount Type*" name="discountType" placeHolder="Discount Type" required /></td>
                            <td className="col-xl-3"><InputGroup value={items.discount}  onChange={(event)=>handleSelectedChange(event,index)} enabled label="Discount*" name="discount"  type="text" placeHolder="Enter Discount" required /></td> 
                           
                        </tr>
                    )}
                    </tbody>
                    </table>
                    <HDiv className="justify-content-end">
                    </HDiv>
                    <div className='form-footer d-flex justify-content-end gap-3  mt-3'>
                        <button type="button" className="mb-3 btn btn-outline-secondary" onClick={() => onCancel()} data-bs-dismiss="modal">Cancel</button>
                        <button className="mb-3  btn btn-primary" type='button' onClick={()=>handleAdd()} >Add Color</button>
                        <button className="mb-3  btn btn-primary" type='submit'>{update ? "Update": "Save"}</button>
                    </div>
                    {apiInProgress && <ProcessLoader />}
                </form>
                </VDiv>
            </VDiv>
        </VDiv>
    )
}