import { createRef, useEffect, useState } from 'react'
import { deleteImagebyColorProduct } from '../service/productImageService';
import { useParams } from 'react-router';
import { toast } from '../components/general/alert';

export default function useFileUploader({onFileSelect,index,multiple,value, enabled}) {
    const fileRef = createRef()

    const { tenantId, productId } = useParams()

    const [files, setFiles] = useState([])

    const [openFile, setOpenFile] = useState(false)

    const [show, setShow] = useState(true)

    const [selectedFile, setSelectedFile] = useState();

    const openFileSelector = () => {
        fileRef.current.click()
    }
    useEffect(()=>{
        if(value!== null){
            setFiles(value)
        }
    },[value])
    const closeFile = (event) => {
        event.stopPropagation()
        setOpenFile(false)
    }

    const onFileClick = (event, file) => {
        event.stopPropagation()
        setSelectedFile(file)
        enabled && setOpenFile(true)
    }

    const handleFileSelect = (event) => {
        let limit = value ? 6 - value.length : 6

        if(event.target.files.length <= limit){
        
        event.stopPropagation()
        setShow(false)
        if (event.target.files) {
            let files = []
            for(let i = 0; i < event.target.files.length; i++) {
                let selectedFile = event.target.files[i];
                files.push({
                    name: selectedFile.name,
                    image: URL.createObjectURL(selectedFile),
                    type: selectedFile.type,
                    file: selectedFile
                })
            }

            if(multiple){
                setFiles(([...value,...files]))

            }else{
                setFiles(files)
            }
            if(multiple && onFileSelect ){
                onFileSelect([...value,...files],index)
            }

            if(onFileSelect && !multiple ) {
                onFileSelect(files)
            }
           
        }}else{
            toast("error", `Please add only ${limit} images`)
        }
        // setOpenFile(true)
        
    }

    const removeFile =(indexs) =>{
    const selected = [...files]
        selected.splice(indexs,1)
        setFiles(selected)
        onFileSelect([...selected],index)
    }

    const handleImageDeleteClick = async (row,index) => {
        const response = await deleteImagebyColorProduct(tenantId, productId,row.colorId, row.id)
        if(response && response === true){
            removeFile(index)
            toast("success","Deleted Successfully")
        }
    }



    return { fileRef, openFileSelector, handleFileSelect, files, openFile, onFileClick, selectedFile, closeFile,show ,removeFile,handleImageDeleteClick}
}
