import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { getBrands } from "../service/BrandService"
import { getCategories } from "../service/CategoryService"
import { deleteProduct, getProducts, getProductsByName } from "../service/productService"
import { getProductDetailUrl } from '../utils/Constant'
import { toast } from "../components/general/alert"

export default function useProduct() {

    const modalId = "CategoryModal"

    const [list, setList] = useState([])

    const [formData, setFormData] = useState(undefined)

    const [action, setAction] = useState("POST")

    const [loading, setLoading] = useState(true)

    const { tenantId } = useParams()

    const [showForm, setShowForm] = useState(false)


    const [colorForm, setcolorForm] = useState(false)

    const [category, setCategory] = useState([])

    const [brand, setBrand] = useState([])

    const [update, setUpdate] = useState(true)

    const [updateForm, setUpdateForm] = useState(false)

    const [searchValue, setSearchValue] = useState("")

    const navigate = useNavigate()

    const getHeaders = () => {
        const headers = [
            { title: "Name", type: "label", property: "name" },
            { title: "Description", type: "label", property: "description" },
            { title: "Action", type: "action" }
        ]
        return headers;
    }

    const handleSubmit = async (response, saveAndAddAnother) => {
        if (!saveAndAddAnother) {
            setShowForm(false)
        }
        if (response) {
            setShowForm(false)
        }
        fetchAll()
    }

    const handleChange = async (event) => {
        setSearchValue(event.target.value)
        if (event.target.value.length > 0) {
            const response = await getProductsByName(tenantId, event.target.value)
            let array = []
            for (let i = 0; i < response.length; i++) {
                if (response[i].deleted === false) {
                    array.push({ "name": response[i]["name"], description: response[i].description, category: response[i].category !== null ? response[i].category.name : "", categoryId: response[i].category !== null ? response[i].category.id : "", brand: response[i].brand !== null ? response[i].brand.name : "", brandId: response[i].brand !== null ? response[i].brand.id : "", id: response[i].id, colors: response[i].colors, attributes: response[i].attributes, prices: response[i].prices })
                }
            }
            setList(array)
        }else if(event.target.value.length === 0) {
            fetchAll();
        }


    }

    const fetchAll = async () => {
        const response = await getProducts(tenantId)
        let array = []
        for (let i = 0; i < response.length; i++) {
            if (response[i].deleted === false) {
                array.push({ "name": response[i]["name"], description: response[i].description, category: response[i].category !== null ? response[i].category.name : "", categoryId: response[i].category !== null ? response[i].category.id : "", brand: response[i].brand !== null ? response[i].brand.name : "", brandId: response[i].brand !== null ? response[i].brand.id : "", id: response[i].id, colors: response[i].colors, attributes: response[i].attributes, prices: response[i].prices })
            }
        }
        setList(array)
        const responseCategory = await getCategories(tenantId)
        let arr = []
        for (let i = 0; i < responseCategory.length; i++) {
            if (responseCategory[i].deleted === false && responseCategory[i].parentCategoryId !== null) {
                arr.push({ name: responseCategory[i].name, key: responseCategory[i].id })
            }
        }
        setCategory(arr)
        const responseBrand = await getBrands(tenantId)
        let arrayBrand = []
        for (let i = 0; i < responseBrand.length; i++) {
            if (responseBrand[i].deleted === false) {
                arrayBrand.push({ name: responseBrand[i].name, key: responseBrand[i].id })
            }
        }
        setBrand(arrayBrand)
        setLoading(false)
    }

    const handleClick = (data) => {
        navigate(getProductDetailUrl(tenantId, data.id))
    }

    const handleAddClick = () => {
        setUpdate(false)
        setShowForm(true)
        setFormData({})
    }

    const handleEditClick = (row) => {
        console.log(row,)
        setFormData(row)
        setShowForm(true)
        setUpdate(true)

    }

    const handleDeleteClick = async (row) => {
        const response = await deleteProduct(tenantId, row.id)
        if (response && response === true) {
            toast("success", "Deleted Successfully")
            fetchAll()
        }
        

    }

    const handleCancelClick = () => {
        setShowForm(false)
    }

    useEffect(() => {
        fetchAll()
    }, [showForm])

    return { showForm, modalId, list, formData, getHeaders, handleSubmit, action, loading, handleAddClick, handleClick, handleEditClick, handleDeleteClick, handleCancelClick, category, brand, update, colorForm, setAction, setcolorForm, updateForm, setShowForm, setUpdateForm, searchValue, handleChange }

}