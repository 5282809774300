import React from 'react'
import  useFileUploader  from '../../hooks/useFileUploader'
import HDiv from '../containers/hdiv'
import VDiv from '../containers/vdiv'
import HGrow from '../elements/h-grow'
import Text from '../elements/texts/text'
import AddButton from './add-button'
import ProcessLoader from '../general/process-loader'
const FileSelector = ({label, className, onFileSelect,required, showPreview = true, multiple = false, showProgress = false, value, update}) => {

    const {fileRef, openFileSelector, handleFileSelect, files, openFile, selectedFile, onFileClick, closeFile, removeFile, show} = useFileUploader({onFileSelect})
    return(
        <VDiv className={`edc-input-group file-uploader ${className ? className : ''}`} onClick={openFileSelector}>
            <HDiv>
                <label className="form-label">{label}</label>
                <HGrow />
                <AddButton />
            </HDiv>
            {showPreview && files && multiple ? 
            <HDiv className="mt-3 mb-3 relative">
                {files.map((file) => 
                    <VDiv onClick={(event) => onFileClick(event, file)} className='file-preview' key={file.name} style={{backgroundImage: `url(${file.image})`}}>
                        <Text size="sm" className="file-name"/>
                        <img className='close' src="/assets/images/icons/close-btn.png" alt="close" onClick={() => removeFile(file)} />
                    </VDiv>
                )}
                {showProgress &&
                    <ProcessLoader />
                }
            </HDiv> : null}
            
            {showPreview && files && !multiple && files.map((file) => 
                <VDiv onClick={(event) => onFileClick(event, file)}  className="cover-preview mt-3 relative" key={file.name} style={file.type === 'video/mp4' ? {}: {backgroundImage: `url(${file.image})`}}>
                    { file.type === 'video/mp4' &&
                    <video className='video' width="100%" height={"100%"} controls>
                        <source src={file.image} type="video/mp4" />
                    </video>
                    }
                    {showProgress &&
                        <ProcessLoader />
                    }
                </VDiv>
            )}
            {openFile ?
              (<VDiv className="show-file-container">
                <img className='file' alt={selectedFile.name} src={selectedFile.image} />
                <img className='close-btn' src='/assets/images/icons/icon-close-white.png' alt='close' onClick={(event) => closeFile(event)} />
             </VDiv>)
               : null
                   
            }
            {update && show && value !==null ?(<div className='preview-img w-100 '>
                <img className='file ' alt={"preview-img"} src={value} width="100%" height="100%" />
               </div>): null}
            {!update ?<input className='fileSelector' type="file" name='file' onChange={(event) => handleFileSelect(event)} ref={fileRef} multiple={multiple} required={required} />:
            <input className='fileSelector' type="file" name='file' onChange={(event) => handleFileSelect(event)} ref={fileRef} multiple={multiple} />}

        </VDiv>
    )
}

export default FileSelector