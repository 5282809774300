import { getDealUrl, getDealsUrl, saveDealUrl } from "./ApiUrls";
import { Delete, Put, get, post } from "./Service";

export const getDeals = async (tenantId) => {
    const response = await get(getDealsUrl(tenantId));
    if(!response) {
        return []
    }
    return response;
};

export const getDeal = async (tenantId,id) => {
    const response = await get(getDealUrl(tenantId,id));
    if(!response) {
        return []
    }
    return response;
};

export const saveDeal = async (tenantId, data) => {
    const response = await post(saveDealUrl(tenantId), data);
    return response;
};

export const updateDeal = async (tenantId, data) => {
    const response = await Put(saveDealUrl(tenantId), data);
    return response;
};

export const deleteDeal = async (tenantId, key) => {
    const response = await Delete(getDealUrl(tenantId, key));
    return response;
};