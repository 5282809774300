import { useState } from 'react'
import { useParams } from "react-router-dom"
import { getUploadApiUrl } from '../../service/ApiUrls'
import { saveImagebyColorProduct, updateImagebyColorProduct } from '../../service/productImageService';
import { upload } from '../../service/Service'

export default function useProductImageForm({id, onSave, formData,values, update}) {
    
    const { tenantId,productId } = useParams();
    console.log(formData)
    const newData = {name:"", description:"", imageUrl:undefined, deleted:false, colorId:""}
    const [data, setData] = useState(formData !== undefined ?{...formData} : {...newData})

    const [files, setFiles] = useState([])

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const [apiInProgress, setApiInProgress] = useState(false)

    const handleInputChange = (event) => {
        event.stopPropagation()
        setData((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }));
    }

    const onFileSelect = (files) => {
        console.log(files)
        setFiles(files)
    } 

    const handleSubmit = async (saveAndAddAnother) => {
        setApiInProgress(true)
        if(files && files.length > 0){
            data.imageUrl = await uploadFile()
        } 
        const imgResponse =  !update ? await saveImagebyColorProduct(tenantId,productId, formData.colorId, data):await updateImagebyColorProduct(tenantId,productId, formData.colorId, data)
        setApiInProgress(false)
        if(onSave) {
            onSave({imgResponse, saveAndAddAnother})
        }
        setData({...newData})
    }

    const uploadFile = async () => {
        setUploadInProgress(true)
        let file = files[0].file
        const data = {
            tenantId,
            userId: localStorage.getItem("userId"),
            file: file
        }
        let response = await upload(getUploadApiUrl(tenantId), data)
        setUploadInProgress(false)
        if(response) {
            return response.mediaUrl;
        }
    }

    return { handleInputChange, handleSubmit, onFileSelect, data, uploadInProgress, apiInProgress}
}