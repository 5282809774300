import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { getUploadApiUrl } from '../../service/ApiUrls'
import { saveProductPricebyColorProduct, updateProductPriceTypebyColorProduct } from '../../service/productPriceService';

import { upload } from '../../service/Service'

export default function useProductPriceForm({id, onSave, formData, update}) {

    const { tenantId,productId } = useParams();

    console.log(formData);

    const newData = { isPrice: undefined,wasPrice: undefined,discount: undefined,discountType: "",productSkuId: "",productId}
    
    const [data, setData] = useState(formData)

    const [files, setFiles] = useState([])

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const [apiInProgress, setApiInProgress] = useState(false)

    const discountType = [
        {name:"Percentage",key:"PERCENTAGE"},
        {name:"Amount",key:"AMOUNT"}
    ]

    useEffect(()=>{
        setData(formData)
    },[formData])
    
    const handleInputChange = (event) => {
        event.stopPropagation()
        setData((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }));
        if (event.target.name === "discountType") {
            if (data.isPrice !== "" && data.wasPrice !== "") {
                if (event.target.value === "PERCENTAGE") {
                    const value = ((data.wasPrice - data.isPrice) / data.wasPrice) * 100
                    setData(state => ({ ...state, "discount": parseInt(value) }))
                }
                if (event.target.value === "AMOUNT") {
                    const value = (data.wasPrice - data.isPrice)
                    setData(state => ({ ...state, "discount": value }))
                }

            }
        }
    }

    const onFileSelect = (files) => {
        console.log(files)
        setFiles(files)
    } 

    const handleSubmit = async (saveAndAddAnother) => {
        setApiInProgress(true)
        if(files && files.length > 0){
            data.imageUrl = await uploadFile()
        }

        const priceResponse = !update ? await saveProductPricebyColorProduct(tenantId, productId,null,null,null, data)
                                 : await updateProductPriceTypebyColorProduct(tenantId, productId,null,null,null, data)
        setApiInProgress(false)
        if(onSave) {
            onSave({priceResponse, saveAndAddAnother})
        }
        setData({...newData})
    }

    const uploadFile = async () => {
        setUploadInProgress(true)
        let file = files[0].file
        const data = {
            tenantId,
            userId: localStorage.getItem("userId"),
            file: file
        }
        let response = await upload(getUploadApiUrl(tenantId), data)
        setUploadInProgress(false)
        if(response) {
            return response.mediaUrl;
        }
    }

    return { handleInputChange, handleSubmit, onFileSelect, data, uploadInProgress, apiInProgress, discountType}
}