import React from "react";
import FormPopup from "./form-popup";
import InputGroup from "../inputs/input-group";
import TextArea from "../inputs/textarea";
import FileSelector from "../inputs/file-selector";
import ProcessLoader from "../general/process-loader";

export default function NameDescriptionPopup({title, onSave, onCancel, formData, hook, nameRequired , fileRequired, update}) {
    const { handleInputChange, handleSubmit, onFileSelect, data, uploadInProgress, apiInProgress} = hook({onSave, formData, update});
    return(
        <FormPopup title={title} onSave={handleSubmit} onCancel={onCancel} update={update}>
            <InputGroup value={data.name} className="mb-3 col-md-12" onChange={handleInputChange} enabled label="Name*" name="name"  type="text" placeHolder="Enter name" required={nameRequired} />
            <TextArea value={data.description} className="mb-3 col-md-12" onChange={handleInputChange} label="Description" name="description" placeHolder="Enter description"  />
            <FileSelector onFileSelect={onFileSelect} value={data.imageUrl} label="Upload Image" showProgress={uploadInProgress} required={fileRequired} update={update}/>
            {apiInProgress && <ProcessLoader />}
        </FormPopup>
    )
}