import React from "react";
import useNewArrivals from "../hooks/useNewArrivals";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import NewArrivalsPopup from "../components/popups/newArrivalPopup";
import useNewArrivalsForm from "../hooks/forms/useNewArrivalsForm";
import DataTable from "react-data-table-component";
import { Box } from "@mui/material";
import DeleteButton from "../components/inputs/delete-button";
import { handleConfirmPopup } from "../components/general/confirmDialog";
function NewArrivals() {
  const {
    showForm,
    modalId,
    list,
    formData,
    handleSubmit,
    action,
    loading,
    handleAddClick,
    handleDeleteClick,
    handleCancelClick,
    update,
    productList,
  } = useNewArrivals();
  const columns = [
    {
      name: 'S. No',
      cell: (row, index) => index + 1,
      width: "80px",
      center: true,
    },
    {
      name: 'Product Name',
      selector: row => row.name,
      sortable: true,
      header: <Box width={"100%"} display={"flex"} justifyContent={"center"}>Name</Box>
    },
    {
      name: "Delete",
      selector: row => <DeleteButton onClick={(event) =>handleConfirmPopup(event, ()=>handleDeleteClick(row), `new-arrival product "${row.name}"`)} />,
      width: "80px",
      center:true
    },
  ];
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card
            title="New Arrivals"
            subTitle="Add/Edit/Delete your categories."
            modalId="masterTypeModal"
            showAddButton
            addLabel="Add New Arrivals"
            onAdd={handleAddClick}
          >
            <DataTable columns={columns} data={list} actions pagination />
          </Card>
          {showForm && (
            <NewArrivalsPopup
              hook={useNewArrivalsForm}
              list={productList}
              action={action}
              id={modalId}
              onCancel={handleCancelClick}
              onSave={handleSubmit}
              nameRequired
              fileRequired
              formData={formData}
              title={update ? `Edit ${formData.name}` : "Add New Arrivals"}
              update={update}
            />
          )}
        </>
      )}
    </div>
  );
}

export default NewArrivals;
