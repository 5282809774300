import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import useProduct from "../hooks/useProduct";
import useProductForm from "../hooks/forms/useProductForm";
import DataTable from "react-data-table-component";
import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";
import UpdateProductPopup from "../components/popups/update-product-form";
import MuiPopUp from "../components/general/newPopup";
import { TextField } from "@mui/material";
import HDiv from "../components/containers/hdiv";
import { handleConfirmPopup } from "../components/general/confirmDialog";


export default function Product() {
    const { showForm, list, loading, handleAddClick, handleEditClick, formData, update, category, brand, handleCancelClick, handleDeleteClick, setShowForm, handleClick, handleChange, searchValue } = useProduct()
    const columns = [
        {
            name: 'S. No',
            cell: (row, index) => index + 1,
            width: "80px",
            center: true,
          },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            fixed: "left",
            width: "300px"
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            width: "270px",

        },
        {
            name: "Category",
            selector: row => row.category,
            sortable: true,
            width: "150px"
        },
        {
            name: "Brand",
            selector: row => row.brand,
            sortable: true,
            width: "100px"
        },
        {
            name: "Edit",
            selector: row => <EditButton onClick={() => handleEditClick(row)} />,
            width: "80px"
        },
        {
            name: "Delete",
            selector: row => <DeleteButton onClick={(event) =>handleConfirmPopup(event, ()=>handleDeleteClick(row), `product "${row.name}"`)} />,
            width: "80px",
            center:true
        },
        {
            name: "Explore",
            selector: row => <img className="float-right explore-btn" src="./assets/images/Arrow-Right.svg" alt="arrow" title="Explore" onClick={() => handleClick(row)} />,
            width: "80px",
            fixed: true

        }
    ];

    return (
        <>
            <Card title="Product manager" subTitle="Add/Edit/Delete your categories." modalId="masterTypeModal" showAddButton addLabel="Add Product" onAdd={handleAddClick}>
                {loading ? <Loader /> : <>
                    <HDiv className={"justify-content-end"}>
                        <TextField
                            label="Search Product"
                            InputProps={{
                                type: 'search',
                            }}
                            className="col-md-3"
                            onChange={(event) => handleChange(event)}
                            value={searchValue}
                        />
                    </HDiv>
                    <DataTable columns={columns} data={list} actions pagination />  </>}
                <MuiPopUp open={showForm} titles={["Product Detail", "Colors"]} handleClose={handleCancelClick}>
                    <UpdateProductPopup handleCancel={handleCancelClick} category={category} brand={brand} formData={formData} hook={useProductForm} update={update} setShowForm={setShowForm} />
                </MuiPopUp>
            </Card>
        </>
    )
}