import { useState } from 'react'
import { useParams } from "react-router-dom"
import { toast } from '../../components/general/alert';
import { saveDeal, updateDeal } from '../../service/DealService';

export default function useDealsForm({id, onSave, formData, update}) {

    const { tenantId } = useParams();
    
    const newData = {name:"", description:"", startDate:"",endDate:"", deleted:false, tenantId}
    
    const [data, setData] = useState(formData !== undefined ? {...formData, tenantId: tenantId} : {...newData})

    const [apiInProgress, setApiInProgress] = useState(false)

    const handleInputChange = (event) => {
        event.stopPropagation()
        setData((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }));
    }

    const handleSubmit = async (saveAndAddAnother) => {
        setApiInProgress(true)
        if(update)  data.id = formData.id 
        const response = !update ? await saveDeal(tenantId, data) : await updateDeal(tenantId, data)
        !update? toast("success","Created Successfully") :  toast("success","Updated Successfully")

        setApiInProgress(false)
        if(onSave) {
            onSave(response, saveAndAddAnother)
        }
        setData({})
    }

    return { handleInputChange, handleSubmit, data, apiInProgress}
}