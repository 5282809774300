import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import NoDataMessage from "../components/general/NoDataMessage";
import NameDescriptionPopup from "../components/popups/name-description-form-popup";
import useBanner from "../hooks/useBanner";
import useBannerForm from "../hooks/forms/useBannerForm";
import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";
import { handleConfirmPopup } from "../components/general/confirmDialog";

export default function Banner() {
    const { showForm, modalId, list, formData, handleSubmit, action, loading, handleAddClick, handleEditClick, handleDeleteClick, handleCancelClick, update } = useBanner()
    return (
        <>
            {loading ? <Loader /> :
                <>
                    <Card title="Banners" subTitle="Add/Edit/Delete your categories." modalId="masterTypeModal" showAddButton addLabel="Add Banner" onAdd={handleAddClick}>
                        {list && list.length > 0 ? <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 g-2">
                            {list && list.length > 0 && list.map((data) =>
                                <div className="col cursor-pointer " style={{ height: 200 }}>
                                    <div className="box position-relative rounded-0">
                                        <img src={data.imageUrl} alt="" width={"100%"} height={"70%"} style={{ objectFit: "cover" }} />
                                        <p className="fw-bolder">{data.name}</p>
                                        <p className="text-truncate">{data.description}</p>
                                        <div className='d-flex actions justify-content-end rounded-0 w-100'>
                                            <EditButton onClick={() => handleEditClick(data)} />
                                            <DeleteButton className="ml-2" onClick={(event) =>handleConfirmPopup(event, ()=>handleDeleteClick(data),`banner "${data.name}"`)} />
                                        </div>

                                    </div>
                                </div>)}
                        </div> : <NoDataMessage modalId="masterTypeModal" />}
                    </Card>
                    {showForm && <NameDescriptionPopup hook={useBannerForm} action={action} id={modalId} fileRequired nameRequired onCancel={handleCancelClick} onSave={handleSubmit} formData={formData} title={update ? `Edit Banner` : "Add Banner"} update={update} />}
                </>
            }
        </>
    )
}