import React from "react";

export default function Footer() {

    return(
        <footer className="content-footer footer bg-footer-theme">
            <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
              <div className="mb-2 mb-md-0">© {new Date().getFullYear()}, made with &nbsp;
                <strong>GJ E-Commerce</strong>
              </div>
              <div>
                
                {/* <a href="" rel="noopener noreferrer" className="footer-link me-4" target="_blank">Terms & Conditions</a>
                <a href="" target="_blank" rel="noopener noreferrer" className="footer-link me-4">Privacy Policy</a>
                <a href="" target="_blank" rel="noopener noreferrer" className="footer-link me-4">Documentation</a> */}
              </div>
            </div>
        </footer>
    )
}