import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import useCategories from "../hooks/useCategories";
import useCategoryForm from "../hooks/forms/useCategoryForm";
import CategoryPopup from "../components/popups/category-form-popup";
import NameDescriptionPopup from "../components/popups/name-description-form-popup";
import { Box } from "@mui/material";
import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";
import DataTable from "react-data-table-component";
import { handleConfirmPopup } from "../components/general/confirmDialog";

export default function Categories() {

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) => index + 1,
            width: "80px",
            center: true,
          },
        {
            name: "Image",
            selector: row => <img className="object-fit-contain" src={row.imageUrl} alt="" width={50} height={50} style={{ objectFit: "contain" }} />,
            sortable: true,
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"start"}><img src={row.imageUrl} alt="" width={50} height={50} style={{objectFit:"contain"}} /></Box>,
            width: "100px"
        },
        {
            name: 'Category Name',
            selector: row => row.name,
            sortable: true,
            header: <Box width={"100%"} display={"flex"} justifyContent={"center"}>Name</Box>
        },

        {
            name: "Edit",
            selector: row => <EditButton onClick={() => { handleEditClick(row) }} />,
            width: "80px",
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"center"}><EditButton onClick={() => { handleEditClick(row) }} /></Box>,

        },
        {
            name: "Delete",
            selector: row => <DeleteButton onClick={(event) =>handleConfirmPopup(event, ()=>handleDeleteClick(row), `category "${row.name}"`)} />,
            width: "80px",
            center:true

        },
        {
            name: "Explore",
            selector: row => <img className="float-right explore-btn" src="./assets/images/Arrow-Right.svg" title="Explore" alt="arrow" onClick={() => handleClick(row)} />,
            width: "80px",
            fixed: true,
            cell: row => <Box width={"100%"} display={"flex"} justifyContent={"center"}><img className="float-right explore-btn" src="./assets/images/Arrow-Right.svg" title="Explore" alt="arrow" onClick={() => handleClick(row)} /></Box>,
        }
    ];
    const { showForm, modalId, list, formData, handleSubmit, showFormSub, action, loading, handleAddClick, handleAddAnotherClick, handleClick, handleEditClick, handleDeleteClick, handleCancelClick, dropdown, update } = useCategories()
    return (
        <>
            <Card title="Categories" subTitle="Add/Edit/Delete your categories." modalId="masterTypeModal" showAddButton onAdd={handleAddClick} onAddAnother={handleAddAnotherClick} showAnotherButton addLabel="Add Category" anotherLabel="Add Subcategory" actions>
                {loading ? <Loader /> :
                    <DataTable columns={columns} data={list} actions pagination />}
            </Card>
            {showFormSub && <CategoryPopup hook={useCategoryForm} action={action} id={modalId} onCancel={handleCancelClick} onSave={handleSubmit} formData={formData} dropdown={dropdown} title={update ? `Edit ${formData.name}` : "Add Subcategory"} update={update} />}
            {showForm && <NameDescriptionPopup hook={useCategoryForm} action={action} id={modalId} onCancel={handleCancelClick} onSave={handleSubmit} formData={formData} title={update ? `Edit ${formData.name}` : "Add Category"} update={update} nameRequired fileRequired />}

        </>
    )
}