import React from "react";
import FormPopup from "./form-popup";
import ProcessLoader from "../general/process-loader";
import { Autocomplete, TextField } from "@mui/material";


export default function NewArrivalsPopup({
  title,
  onSave,
  onCancel,
  formData,
  hook,
  update,
  list,
}) {
  const { handleSubmit, data, apiInProgress, searchValue, results, handleOptionChange, handleChange } = hook({
    onSave,
    formData,
    update,
  });
  console.log(data.productId, "data.productId");
  return (
    <FormPopup
      title={title}
      onCancel={onCancel}
      onSave={handleSubmit}
      update={update}
    >
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        className="mb-3"
        options={results.map((option) => option.name)}
        onChange={handleOptionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Product"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            onChange={(event) => handleChange(event)}
            value={searchValue}
            required
          />

        )}
      />
      {apiInProgress && <ProcessLoader />}
    </FormPopup>
  );
}
