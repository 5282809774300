import { 
    getCategoriesApiUrl,
    getSaveCategoryApiUrl,
    getCategoryApiUrl
 } from "./ApiUrls";
import { get, Delete, Put, Post } from "./Service";


export const getCategories = async (tenantId) => {
    const response = await get(getCategoriesApiUrl(tenantId));
    if(!response) {
        return []
    }
    return response;
};

export const getCategory = async (tenantId,categoryId) => {
    const response = await get(getCategoryApiUrl(tenantId,categoryId));
    if(!response) {
        return {}
    }
    return response;
};

export const saveCategory = async (tenantId, data) => {
    const response = await Post(getSaveCategoryApiUrl(tenantId), data);
    return response;
};

export const updateCategory = async (tenantId, data) => {
    const response = await Put(getSaveCategoryApiUrl(tenantId), data);
    return response;
};

export const deleteCategory = async (tenantId, key) => {
    const response = await Delete(getCategoryApiUrl(tenantId , key));
    return response;
};