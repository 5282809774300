import { 
    getProductPriceBycolorProductApiUrl,
    getProductPricesbyColorProductApiUrl,
    getSaveProductPricebyColorProductApiUrl,
 } from "./ApiUrls";
import { get,  Delete, Post, Put } from "./Service";


export const getProductPricesbyColorProduct = async (tenantId, productId,colorId,pSkuTypeId,pSkuId) => {
    const response = await get(getProductPricesbyColorProductApiUrl(tenantId, productId,colorId,pSkuTypeId,pSkuId));
    if(!response) {
        return []
    }
    return response;
};

export const getProductPricebyColorProduct = async (tenantId, productId,colorId,pSkuTypeId,pSkuId,priceId) => {
    const response = await get(getProductPriceBycolorProductApiUrl(tenantId, productId,colorId,pSkuTypeId,pSkuId, priceId));
    if(!response) {
        return []
    }
    return response;
};

export const saveProductPricebyColorProduct = async (tenantId, productId,colorId,pSkuTypeId,pSkuId, data) => {
    const response = await Post(getSaveProductPricebyColorProductApiUrl(tenantId, productId,colorId,pSkuTypeId,pSkuId), data);
    return response;
};

export const updateProductPriceTypebyColorProduct = async (tenantId, productId,colorId,pSkuTypeId,pSkuId, data) => {
    const response = await Put(getSaveProductPricebyColorProductApiUrl(tenantId, productId,colorId,pSkuTypeId,pSkuId), data);
    return response;
};

export const deleteProductPriceTypebyColorProduct = async (tenantId, productId,colorId,pSkuTypeId,pSkuId, key) => {
    const response = await Delete(getProductPriceBycolorProductApiUrl(tenantId, productId,colorId,pSkuTypeId,pSkuId, key));
    return response;
};