import { 
    getProductSkuBycolorProductApiUrl,
    getProductSkusbyColorProductApiUrl,
    getSaveProductSkubyColorProductApiUrl,
 } from "./ApiUrls";
import { get, Delete, Post, Put } from "./Service";


export const getProducSkusbyColorProduct = async (tenantId, productId,colorId,pSkuTypeId) => {
    const response = await get(getProductSkusbyColorProductApiUrl(tenantId, productId,colorId,pSkuTypeId));
    if(!response) {
        return []
    }
    return response;
};

export const getProductSkubyColorProduct = async (tenantId, productId,colorId,pSkuTypeId) => {
    const response = await get(getProductSkuBycolorProductApiUrl(tenantId, productId,colorId,pSkuTypeId));
    if(!response) {
        return []
    }
    return response;
};

export const saveProductSkubyColorProduct = async (tenantId, productId,colorId,pSkuTypeId, data) => {
    const response = await Post(getSaveProductSkubyColorProductApiUrl(tenantId, productId,colorId,pSkuTypeId), data);
    return response;
};

export const updateProductSkubyColorProduct = async (tenantId, productId,colorId,pSkuTypeId, data) => {
    const response = await Put(getSaveProductSkubyColorProductApiUrl(tenantId, productId,colorId,pSkuTypeId), data);
    return response;
};

export const deleteProductSkubyColorProduct = async (tenantId, productId,colorId, key) => {
    const response = await Delete(getProductSkuBycolorProductApiUrl(tenantId, productId,colorId, key));
    return response;
};