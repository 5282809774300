import { 
    getBrandsApiUrl,
    getBrandApiUrl,
    getSaveBrandApiUrl
 } from "./ApiUrls";
import { post, get, put, Delete } from "./Service";


export const getBrands = async (tenantId) => {
    const response = await get(getBrandsApiUrl(tenantId));
    if(!response) {
        return []
    }
    return response;
};

export const getBrand = async (tenantId,categoryId) => {
    const response = await get(getBrandApiUrl(tenantId,categoryId));
    if(!response) {
        return []
    }
    return response;
};

export const saveBrand = async (tenantId, data) => {
    const response = await post(getSaveBrandApiUrl(tenantId), data);
    return response;
};

export const updateBrand = async (tenantId, data) => {
    const response = await put(getSaveBrandApiUrl(tenantId), data);
    return response;
};

export const deleteBrand = async (tenantId, key) => {
    const response = await Delete(getBrandApiUrl(tenantId, key));
    return response;
};