import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { deleteCategory, getCategories, getCategory } from "../service/CategoryService"
import { toast } from "../components/general/alert"

export default function useCategoryDetail() {
     
     let modalId = "categoryDetail"
    const [category, setCategory] = useState([])

    const [formData, setFormData] = useState(undefined)

    const [loading, setLoading] = useState(true)

    const { tenantId, categoryId } = useParams()

    const [showForm, setShowForm] = useState(false)

    const [dropdown,setDropdown] = useState([])

    const [update, setUpdate] = useState(true)


    const handleSubmit = async (response, saveAndAddAnother) => {
        if(!saveAndAddAnother) {
            setShowForm(false)
        }
        fetchCategory()
    }

    const fetchCategory = async () => {
        const response = await getCategory(tenantId, categoryId)
        console.log(response)
        let array = []
        for(let i=0; i<response.subCategories.length ; i++){
            if( response.subCategories[i].deleted ===false ){
               array.push({...response.subCategories[i]}) 
            }
        }
        setCategory(array)
        const res = await getCategories(tenantId)
        setDropdown(res.map(items =>({name:items.name,key:items.id})))
        setLoading(false)
    }

    const handleAddClick = () => {
        setUpdate(false)
       setShowForm(true)
       setFormData(undefined)
    }

    const handleEditClick = (row) => {
        console.log(row)
        setFormData(row)
        setShowForm(true)
        setUpdate(true)
    }

    const handleDeleteClick = async (row) => {
        const response = await deleteCategory(tenantId,row.id)
        if(response && response === true){
            toast("success","Deleted Successfully")
        }
        fetchCategory()
    }

    const handleCancelClick = () => {
        setShowForm(false)
    }

    useEffect(() => {
        fetchCategory()
    }, [showForm])

    return {showForm, formData, handleSubmit, category, loading, handleAddClick, handleEditClick, handleDeleteClick, handleCancelClick, dropdown,modalId, update}

}