import React from "react"
import Card from "../components/cards/card"
import useDealProducts from "../hooks/useDealProducts"
import DealProductPopup from "../components/popups/deal-product-form-popup"
import useDealProductForm from "../hooks/forms/useDealProductForm"
import Loader from "../components/general/loader"
import DeleteButton from "../components/inputs/delete-button"
import HDiv from "../components/containers/hdiv"
import { Box } from "@mui/material"
import { useNavigate } from "react-router"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DataTable from "react-data-table-component"
import EditButton from "../components/inputs/edit-button"
import ProductPricePopup from "../components/popups/product-forms/product-price-form-popup"
import useProductPriceForm from "../hooks/forms/useProductPriceForm"
import { handleConfirmPopup } from "../components/general/confirmDialog"

export default function DealProducts(){
    const { list, showForm, handleForm,handleCancelForm,formData, update, handleSubmit, loading, handleDelete, handleEdit, priceForm, priceFormData} = useDealProducts()

   const navigate = useNavigate()
   const columns = [
    {
        name: 'S. No',
      cell: (row, index) => index + 1,
      width: "80px",
      center: true,
    },
    {
      name: 'Product Name',
      selector: row => row.product.product !== null ? row.product.product.name : "",
      sortable: true,
    },
    {
      name: 'Current Price',
      selector: row => row.isPrice ? row.isPrice : "",
      sortable: true,
      header: <Box width={"100%"} display={"flex"} justifyContent={"center"}>Name</Box>
    },
    {
      name: 'Old Price',
      selector: row => row.wasPrice ? row.wasPrice : "",
      sortable: true,
      header: <Box width={"100%"} display={"flex"} justifyContent={"center"}>Name</Box>
    },
    {
      name: 'Discount',
      selector: row => row.discount ? row.discount : "",
      sortable: true,
      header: <Box width={"100%"} display={"flex"} justifyContent={"center"}>Name</Box>
    },
    {
      name: "Edit",
      selector: row => <EditButton onClick={() => handleDelete(row)} />,
      width: "80px",
      cell: row => <Box width={"100%"} display={"flex"} justifyContent={"center"}><EditButton onClick={() => handleEdit(row)} /></Box>,

    },
    {
      name: "Delete",
      selector: row => <DeleteButton onClick={(event) =>handleConfirmPopup(event,()=>handleDelete(row), `deal-product "${row.product.product !== null ? row.product.product.name : ""}"`)} />,
      width: "80px",
      center:true

    },
  ];

    return(
        <>
          
        <Card title={<HDiv className={"align-items-center gap-3"}><Box onClick={()=>navigate(-1)}><ArrowBackIcon className="cursor-pointer"/></Box><p>Deal Products</p></HDiv>} subTitle="Add/Edit/Delete." modalId="masterTypeModal" showAddButton addLabel="Add Product" onAdd={handleForm}  >
        {loading ? <Loader /> : 
                // <div className="row row-cols-xl-6 row-cols-lg-4 row-cols-md-3 row-cols-xl-3 g-3">
                //     {list && list.length > 0 ? list.map((item) => 
                //       <div className="col cursor-pointer box rounded-3 position-relative">
                //       <div className="card position-relative border-0 " style={{height:180}}>
                //             <img src={item.product !==null && item.product.colors !== null && item.product.colors[0].color.productImages !==null && item.product.colors[0].color.productImages[0].imageUrl ? item.product.colors[0].color.productImages[0].imageUrl : "./assets/images/placeholder-image.png"} className="card-img p-4 w-100 h-100" alt="..." loading="lazy" style={{ mixBlendMode: "multiply"}}/>
                //           <div className="card-img-overlay d-flex flex-column justify-content-end p-0">
                //               <div className="" style={{ "background": "linear-gradient(to right bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2))", "backdrop-filter": "blur(1rem)" }}>
                //                   <h5 className="card-title fs-6 fw-semibold text-truncate text-dark m-0 px-1"><small>{item.product !==null && item.product.product !==null && item.product.product.name}</small></h5>
                //                   <p className="card-text fs-6 text-truncate m-0 text-dark px-1"><small>{item.product !==null && item.product.product !==null && item.product.product.description}</small></p>
                //               </div>
                //           </div>
                //       </div>
                //       <div className='d-flex actions justify-content-end' style={{"background": "linear-gradient(to right bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2))" }}>
                //             <DeleteButton className="ml-2" onClick={()=>handleDelete(item)}/>
                //         </div>
                //   </div>)
                //      : <NoDataMessage />}
                // </div>
                list && list.length > 0 && <DataTable columns={columns} data={list} actions pagination />}
                       
            </Card>
             {showForm && <DealProductPopup hook={useDealProductForm} onSave={handleSubmit} onCancel={handleCancelForm} fileRequired formData={formData} title={update ? `Edit Deal Product`:"Add Deal Product"} update={update}/>}
             {priceForm && <ProductPricePopup hook={useProductPriceForm} onSave={handleSubmit} onCancel={handleCancelForm}  formData={priceFormData} title={update && "Update Price"} update={update}/>}
             </>
    )
}