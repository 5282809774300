import { useState } from 'react'
import { useParams } from "react-router-dom"
import { saveCategory, updateCategory } from '../../service/CategoryService';
import { getUploadApiUrl } from '../../service/ApiUrls'
import { upload } from '../../service/Service'
import { toast } from '../../components/general/alert';

export default function useCategoryForm({id, onSave, formData,update}) {

    const { tenantId, categoryId } = useParams();
    const newData = {name:"", description:"", parentCategoryId:categoryId?categoryId:"", imageUrl:undefined, deleted:false, tenantId}
    const [data, setData] = useState(formData !== undefined ? {...formData,parentCategoryId:categoryId?categoryId:"",tenantId: tenantId} : {...newData})

    const [files, setFiles] = useState([])

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const [apiInProgress, setApiInProgress] = useState(false)

    const handleInputChange = (event) => {
        event.stopPropagation()
        setData((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }));
        console.log(event)
    }

    const onFileSelect = (files) => {
        console.log(files)
        setFiles(files)
    } 

    const handleSubmit = async (saveAndAddAnother) => {
        setApiInProgress(true)
        if(files && files.length > 0){
            data.imageUrl = await uploadFile()
        }   
            if(update)  data.id = formData.id 
            const response = !update? await saveCategory(tenantId, data) :await updateCategory(tenantId, data)
            if(response.status === 200){
               !update? toast("success","Created Successfully") :  toast("success","Updated Successfully")
                
            }
            setApiInProgress(false)
            if(onSave) {
                onSave(response.data, saveAndAddAnother)
            }
            setData({})
    }

    const uploadFile = async () => {
        setUploadInProgress(true)
        let file = files[0].file
        const data = {
            tenantId,
            userId: localStorage.getItem("userId"),
            file: file
        }
        let response = await upload(getUploadApiUrl(tenantId), data)
        setUploadInProgress(false)
        if(response) {
            return response.mediaUrl;
        }
    }


    return { handleInputChange, handleSubmit, onFileSelect, data, uploadInProgress, apiInProgress}
}