import React from "react";
import useMenu from "../../hooks/useMenu";
import MenuItem from "./menu-item";
/* eslint-disable */
export default function Menu() {

    const {items, handleSidebar, handleScroll} = useMenu() 
    return (
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-white" id="sidenav-main">
        <div className="sidenav-header">
          <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-block d-xl-none" aria-hidden="true" id="iconSidenav" onClick={()=>handleSidebar()}></i>
          <a className="navbar-brand m-0" href>
            <img src="./assets/images/logo.svg" alt="e-commerce" height="40px" className="navbar-brand-img h-100" />
            <span className="ms-1 font-weight-bold"></span>
          </a>
        </div>
        
        
        <div className="collapse navbar-collapse  w-auto" id="sidenav-collapse-main">
          <ul className="navbar-nav">
            {items.map((item, index) => {
              return <MenuItem key={`menu_item_${index}`} data={item} index={index} onClick={handleScroll} />
            })}
          </ul>
        </div>
      </aside>
    );
}
