import { useEffect, useState } from "react"
import { deleteOrder, getOrder, updateOrder } from "../service/orderService"
import { getOrderDetailUrl } from "../utils/Constant"
import { useNavigate, useParams } from "react-router"
import { toast } from "../components/general/alert"

export default function useOrderHistory() {
    const [list ,setList] = useState([])
    const [show, setShow] = useState(false)
    const [order, setOrder] = useState()

    const orderList = [
        {name:"Shipped", key:"SHIPPED"},
        {name:"Delivered", key:"DELIVERED"},
        {name:"Cancelled", key:"CANCELLED"},
        {name:"Rejected", key:"REJECTED"},
        {name:"Ordered", key:"ORDERED"},
    ]
    const {tenantId} = useParams()
    const navigate = useNavigate()
    const fetchAll = async () => {
        const response = await getOrder(tenantId)
        let array = []
        for(let i=0; i<response.length ; i++){
            if(response[i].deleted ===false ){
                array.push({...response[i],edit:false}) 
            }
        }
        const sortedData =  array.sort((a, b) => b.createdOn - a.createdOn);
        setList(sortedData)
    }

    useEffect(()=>{
        fetchAll()
    },[])

    const handleFdit= (row)=>{
        const qwe = [...list]
        const index = qwe.indexOf(row)
        qwe[index]["edit"] = true
        setList(qwe)
    }

    const handleClick = (data) => {
        navigate(getOrderDetailUrl(tenantId, data.id))
    }
    const handleUpdate = async (id,status) => {
        const response = await updateOrder(id, status)
        if(response.status === 200) {
           
            toast("success","Updated Successfully")
            fetchAll()
            setShow(false)
        }
    }

    const handleDelete = async(data) => {
        const response = await deleteOrder(data.id)
        if(response.status === 200) {
                toast("success","Deleted Successfully")
        }
    }
    const handleChange = (event,row)=>{
        handleUpdate(row.id,event.target.value)
    }
    return { list, handleClick, handleDelete , handleUpdate, show,setShow,handleChange, orderList,order,setOrder, handleFdit}

   

}