import { 
    getBannersApiUrl,
    getBannerApiUrl,
    saveStockUrl,
    getStockUrl
 } from "./ApiUrls";
import { post, get, put, Delete } from "./Service";


export const getStocks = async (tenantId) => {
    const response = await get(getBannersApiUrl(tenantId));
    if(!response) {
        return []
    }
    return response;
};

export const getStock = async (tenantId,id) => {
    const response = await get(getStockUrl(tenantId,id));
    if(!response) {
        return []
    }
    return response;
};

export const saveStock = async (tenantId, data) => {
    const response = await post(saveStockUrl(tenantId), data);
    return response;
};

export const updateStock = async (tenantId, data) => {
    const response = await put(saveStockUrl(tenantId), data);
    return response;
};

export const deleteStock = async (tenantId, key) => {
    const response = await Delete(getBannerApiUrl(tenantId, key));
    return response;
};