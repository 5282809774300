import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { deleteCategory, getCategories} from "../service/CategoryService"
import { getCategoryDetailUrl } from '../utils/Constant'
import { toast } from "../components/general/alert"

export default function useCategorys() {

    const modalId = "CategoryModal"

    const [list, setList] = useState([])

    const [dropdown,setDropdown] = useState([])

    const [formData, setFormData] = useState(undefined)

    const [action, setAction] = useState("POST")

    const [loading, setLoading] = useState(true)

    const { tenantId } = useParams()

    const [showForm, setShowForm] = useState(false)

    const [update, setUpdate] = useState(true)

    const [showFormSub, setShowFormSub] = useState(false)

    const navigate = useNavigate()

    const getHeaders = () => {
        const headers = [
            {title: "Name", type: "label", property: "name"},
            {title: "Description", type: "label", property: "description"},
            {title: "Action", type: "action"}
        ]
        return headers;
    }

    const handleSubmit = async (response, saveAndAddAnother) => {
        if(!saveAndAddAnother) {
            setShowForm(false)
        }
        if(response){
            setShowForm(false)
        }
        fetchAll()
    }

    const fetchAll = async () => {
        const response = await getCategories(tenantId)
        let array = []
        let arr =[]
        for(let i=0; i<response.length ; i++){
            if(response[i].parentCategoryId === null && response[i].deleted ===false ){
               array.push({...response[i]}) 
               arr.push({name:response[i].name,key:response[i].id}) 
            }
        }
        setList(array)
        //    const dropdownData  = response.map((items,i) =>(response[i].parentCategoryId !== null )?({name:response[i].name,key:response[i].id}):[])
           setDropdown(arr)
        setLoading(false)
    }

    const handleClick = (data) => {
        navigate(getCategoryDetailUrl(tenantId, data.id))
    }

    const handleAddClick = () => {
        setUpdate(false)
       setShowForm(true)
       setFormData({})
    }

    const handleAddAnotherClick = () => {
        setUpdate(false)
        setShowFormSub(true)
       setFormData({})
    }

    const handleEditClick = (row) => {
        console.log(row)
        setFormData(row)
        setShowForm(true)
        setUpdate(true)
    }
    

    const handleDeleteClick =async (row) => {
       const response = await deleteCategory(tenantId,row.id)
       if(response && response === true){
        toast("success","Deleted Successfully")
    }
       fetchAll()
      
    }

    const handleCancelClick = () => {
        setShowForm(false)
        setShowFormSub(false)
    }

    useEffect(() => {
        fetchAll()
    }, [showForm])

    return {showForm, showFormSub, modalId, list, formData,handleAddAnotherClick, getHeaders, handleSubmit, action, loading, handleAddClick, handleClick, handleEditClick, handleDeleteClick, handleCancelClick, dropdown,update, setAction}

}