import { useState } from 'react'
import { useParams } from "react-router-dom"
import { toast } from '../../components/general/alert';
import { getProductDetail, getProductsByName } from '../../service/productService';
import { saveDealProduct, updateDealProduct } from '../../service/DealProductService';
import { saveProductPricebyColorProduct } from '../../service/productPriceService';

export default function useDealProductForm({ id, onSave, formData, update }) {

    const { tenantId, dealId } = useParams();

    const newData = { dealId: "", productId: "", priceId: "", endDate: "", deleted: false, tenantId }

    const [data, setData] = useState(formData !== undefined ? { ...formData, tenantId: tenantId } : { ...newData })

    const [apiInProgress, setApiInProgress] = useState(false)

    const [results, setResults] = useState([])

    const [searchValue, setSearchValue] = useState("")

    const [product, setProduct] = useState({})

    const [colorIndex, setColorIndex] = useState(undefined)

    const [sizeIndex, setSizeIndex] = useState(undefined)

    const discountType = [
        { name: "Percentage", key: "PERCENTAGE" },
        { name: "Amount", key: "AMOUNT" }
    ]
    console.log(dealId);

    const handleInputChange = (event) => {
        event.stopPropagation()
        setData((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }));
        if (event.target.name === "discountType") {
            if (data.isPrice !== "" && data.wasPrice !== "") {
                if (event.target.value === "PERCENTAGE") {
                    const value = ((data.wasPrice - data.isPrice) / data.wasPrice) * 100
                    setData(state => ({ ...state, "discount": parseInt(value) }))
                }
                if (event.target.value === "AMOUNT") {
                    const value = (data.wasPrice - data.isPrice)
                    setData(state => ({ ...state, "discount": value }))
                }

            }
        }
    }

    console.log(product);
    const handleSubmit = async (saveAndAddAnother) => {
        if(Object.keys(product).length !== 0){
        setApiInProgress(true)
        data.productId = product.product.id
        data.productSkuId = product.colors[colorIndex].productSkuTypeDetail[sizeIndex].productSku[0].id
        const priceResponse = await saveProductPricebyColorProduct(tenantId, product.product.id, product.colors[colorIndex].color.id, product.colors[colorIndex].productSkuTypeDetail[sizeIndex].productSkuType.id, product.colors[colorIndex].productSkuTypeDetail[sizeIndex].productSku[0].id, data)

        if (update) data.id = formData.id
        const payload = {
            "dealId": dealId,
            "productId": product.product.id,
            "priceId": priceResponse.data.id
        }
        const response = !update ? await saveDealProduct(tenantId, payload) : await updateDealProduct(tenantId, payload)
        !update ? toast("success", "Created Successfully") : toast("success", "Updated Successfully")

        setApiInProgress(false)
        if (onSave) {
            onSave(response, saveAndAddAnother)
        }
        setData({})
    }else{
        toast("error", "Please select product from the search list")
    }

    }
    const handleChange = async (event) => {
        setSearchValue(event.target.value)
        setResults([])
        if (event.target.value.length > 0) {

            const response = await getProductsByName(tenantId, event.target.value)
            console.log(response);
            setResults(response)
        }else{
            setResults([])
        }


    }

    const getSelectedOption = (value) => {
        return results.find((option) => option.name === value) || null;
    };

    const handleOptionChange = async (event, value) => {
        const selectedOption = getSelectedOption(value);
        const response = await getProductDetail(tenantId, selectedOption.id)
        setProduct(response)
        if (response && response.colors && response.colors.length > 0)
            setColorIndex(0)
        if (response.colors[0].productSkuTypeDetail !== null && response.colors[0].productSkuTypeDetail.length > 0) {
            setSizeIndex(0)
            handlePrice(0)
        }
    };
    const handlePrice = (index) => {
        if (colorIndex >= 0 && sizeIndex >= 0 && product.colors[colorIndex].productSkuTypeDetail[index].productSku && product.colors[colorIndex].productSkuTypeDetail[index].productSku.length > 0 && product.colors[colorIndex].productSkuTypeDetail[index].productSku[0].productPrice) {
            setData({
                id: product.colors[colorIndex].productSkuTypeDetail[index].productSku[0].productPrice.id,
                isPrice: product.colors[colorIndex].productSkuTypeDetail[index].productSku[0].productPrice.isPrice,
                wasPrice: product.colors[colorIndex].productSkuTypeDetail[index].productSku[0].productPrice.wasPrice,
                discountType: product.colors[colorIndex].productSkuTypeDetail[index].productSku[0].productPrice.discountType,
                discount: product.colors[colorIndex].productSkuTypeDetail[index].productSku[0].productPrice.discount,
                productId: product.product.id,
                productSkuId: product.colors[colorIndex].productSkuTypeDetail[index].productSku[0].id
            })
        }
    }

    return { handleInputChange, handleSubmit, data, apiInProgress, results, searchValue, handleChange, handleOptionChange, product, colorIndex, setColorIndex, sizeIndex, setSizeIndex, handlePrice, discountType }
}