import React from 'react'
import PagePopup from '../containers/page-popup'



export default function FormPopup({onBack, onSave, onCancel, children, title, update}) {
    return(
        <PagePopup onBack={onCancel} showHeader showBackNavigation title={title} update={update}>
            <form className='form needs-validation' onSubmit={(event) =>{event.preventDefault(); onSave(false)}}>
            {children}
            <div className='form-footer mt-3'>
                <button className="mb-3 col-md-12 col-12 btn btn-primary" type='submit'>{update ? "Update": "Save"}</button>
                {/* <button type="button" className="mb-3 col-md-12 col-12 btn btn-primary"  onClick={() => onSave(true)}>Save And Add Another</button> */}
                <button type="button" className="mb-3 col-md-12 col-12 btn btn-outline-secondary" onClick={() => onCancel()} data-bs-dismiss="modal">Cancel</button>
            </div>
            </form>
        </PagePopup>
    )
}