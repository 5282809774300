import React from 'react'

import VDiv from './vdiv'
import Text from '../elements/texts/text'

export default function Form({title, subTitle, children, className, showLogo, onSubmit}) {
    return (
        <VDiv className={`form-container ${className ? className : ''}`}>
            <form className="form needs-validation" onSubmit={onSubmit} >
                {showLogo ? <img className="form-logo mb-3" src="/ecom-business/assets/images/login_image.png" alt="Sree Gokulam Hospital" />: null}
                <Text text={title} size="lg" className="mb-2 block text-start fw-bold" />
                <Text text={subTitle} size="md" className="mb-3 block text-start" />
                <VDiv className="row">
                    {children}
                </VDiv>
            </form>
        </VDiv>
    )
}