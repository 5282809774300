import { useEffect, useState } from "react"
import { deleteDeal, getDeals } from "../service/DealService"
import { useNavigate, useParams } from "react-router"
import { toast } from "../components/general/alert"
import { getDealProductsUrl } from "../utils/Constant"


export default function useDeals() {

    const [list, setList] = useState([])

    const [showForm, setShowForm] = useState(false)

    const [update, setUpdate] = useState(false)

    const [loading, setLoading] = useState(true) 

    const [formData, setFormData] = useState({})

    const navigate = useNavigate()


    const { tenantId } = useParams()

    const getAllDeals = async () => {
        const response = await getDeals(tenantId)
        console.log(response)
        setList(response.filter(item=>item.deleted===false))
        setLoading(false)
    }

    useEffect(() => {
        getAllDeals()
    }, [showForm])

    const handleSubmit = async (response, saveAndAddAnother) => {
        if(!saveAndAddAnother) {
            setShowForm(false)
        }
        if(response){
            setShowForm(false)
        }
        getAllDeals()
    }

    const handleForm = () => {
        setUpdate(false)
        setShowForm(true)
        setFormData({})
    }

    const handleCancelForm = () => {
        setShowForm(false)
    }

    const reverseDate = (date) =>{
        return `${new Date(date).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }).split("/").join("-")}`
    }

    const reverseDateData = (date) =>{
        return `${new Date(date).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }).split("/").reverse().join("-")}`
    }

    const handleEdit = (row) => {
        console.log(row);
        setUpdate(true)
        setShowForm(true)
        setFormData({id:row.id,title:row.title,description:row.description, startDate:reverseDateData(row.startDate),endDate:reverseDateData(row.endDate),deleted:false})
       
    }

    const handleDelete = async (data) => {
        const response = await deleteDeal(tenantId, data.id)
        if (response) {
            toast("success", "Deleted Successfully")
            getAllDeals()
        }
    }

    const handleClick = (data) => {
        navigate(getDealProductsUrl(tenantId, data.id))
    }


    return {
        list,
        showForm,
        update,
        formData,
        handleForm,
        handleCancelForm,
        handleDelete,
        handleEdit,
        reverseDate,
        handleClick,
        handleSubmit,
        loading
    }
}