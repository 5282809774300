import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { deleteBrand, getBrands } from "../service/NewArrivalsService";
import { toast } from "../components/general/alert";
import { getProducts } from "../service/productService";
export default function useCategorys() {
  const modalId = "CategoryModal";
  const [list, setList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [formData, setFormData] = useState(undefined);
  const [action, setAction] = useState("POST");
  const [loading, setLoading] = useState(true);
  const { tenantId } = useParams();
  const [showForm, setShowForm] = useState(false);
  const [update, setUpdate] = useState(true);

  const getHeaders = () => {
    const headers = [
      { title: "Name", type: "label", property: "name" },
      { title: "Description", type: "label", property: "description" },
      { title: "Action", type: "action" },
    ];
    return headers;
  };

  const handleSubmit = async (response, saveAndAddAnother) => {
    if (!saveAndAddAnother) {
      setShowForm(false);
    }
    if (response) {
      setShowForm(false);
    }
    fetchAll();
  };
  const fetchAll = async () => {
    const response = await getBrands(tenantId);
    let array = [];
    for (let i = 0; i < response.length; i++) {
      if (response[i].deleted === false) {
        if (response[i].productId) {
          array.push({
            imageUrl: response[i].productId.imageUrl,
            name: response[i].productId.name,
            ...response[i],
          });
        }
      }
    }
    setList(array);
    setLoading(false);
    const resp = await getProducts(tenantId);

    let ProductArray = [];
    for (let i = 0; i < resp.length; i++) {
      if (resp[i].deleted === false) {
        ProductArray.push({ key: resp[i]["id"], name: resp[i].name });
      }
    }
    setProductList(ProductArray);
  };

  const handleClick = (data) => {
    // navigate(getBrandDetailUrl(tenantId, data.id))
  };

  const handleAddClick = () => {
    setUpdate(false);
    setShowForm(true);
    setFormData({});
  };

  const handleEditClick = (row) => {
    const formData = {
      productId: row.productId.id,
      id: row.id,
      name: row.productId.name,
    };

    setFormData(formData);
    setShowForm(true);
    setUpdate(true);
  };

  const handleDeleteClick = async (row) => {
    const response = await deleteBrand(tenantId, row.id);
    if (response && response === true) {
      toast("success", "Deleted Successfully");
    fetchAll();

    }
  };

  const handleCancelClick = () => {
    setShowForm(false);
  };

  useEffect(() => {
    fetchAll();
  }, [showForm]);

  return {
    showForm,
    productList,
    modalId,
    list,
    formData,
    getHeaders,
    handleSubmit,
    action,
    loading,
    handleAddClick,
    handleClick,
    handleEditClick,
    handleDeleteClick,
    handleCancelClick,
    update,
    setAction,
  };
}
