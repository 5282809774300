import { deleteTopBrandApiUrl, getTopBrandsByTenantIdApiUrl, saveTopBrandApiUrl, updateTopBrandApiUrl } from "./ApiUrls";
import { post, get, put, Delete } from "./Service";

export const saveTopBrand = async (tenantId, data) => {
  const response = await post(saveTopBrandApiUrl(tenantId), data);
  return response;
};


export const getTopBrandsByTenantId =async(tenantId) =>{
    const response = await get(getTopBrandsByTenantIdApiUrl(tenantId));
    return response;
}
 
export const deleteTopBrand = async (tenantId, key) => {
  const response = await Delete(deleteTopBrandApiUrl(tenantId, key));
  return response;
};
export const updateTopBrand = async(tenantId,data) =>{
  const response = await put(updateTopBrandApiUrl(tenantId),data);
  return response;
}