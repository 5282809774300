import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getOrderById } from "../service/orderService"

export default function useOrderDetail() {
     
     const {orderId} = useParams()

    const [detail, setDetail] = useState()

    const [loading, setLoading] = useState(true)

    const fetchCategory = async () => {
        const response = await getOrderById(orderId)
        setDetail(response)
        setLoading(false)
    }

    useEffect(() => {
        fetchCategory()
    }, [])

    return {detail, loading}

}